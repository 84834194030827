import React, { Component } from 'react';
import './DoctorProfile.css'
import Imagepath from '../../../Utility/ImagePath';
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { Stack } from '@mui/system';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ToastMsg from '../../../CommonComponents/ToastMsg';
import moment from 'moment';
class DoctorProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectGender: '',
            selectSpec: '',
            selectCountry: 'India',
            selectState: 'Tamil Nadu',
            loginID: '',
            password: '',
            regNo: '',
            firstName: '',
            middleName: '',
            initial: '',
            dobDate: '',
            qualification: '',
            mobileNo: '',
            emailID: '',
            address1: '',
            address2: '',
            area: '',
            pinCode: '',
            cityName: '',
            telemedicine: false,
            subWalk: false,
            appIns: false,
            isDoc: false,
            aboutMyself: '',
            clinicID: '',
            photo_url: '',
            docList: ''

        }
    }
    componentDidMount() {
        this.getDoctorList()
        const { location } = this.props
        if (location.state.clinic_id) {
            var clinicID = location.state.clinic_id
            this.setState({
                clinicID: clinicID
            })

        }
    }
    postDoctorList = () => {
        try {
            var data = {
                "clinic_id": this.state.clinicID,
                "created_by": "",
                "modified_by": "",
                "login_id": this.state.loginID,
                "password": this.state.password,
                "first_name": this.state.firstName,
                "middle_name": this.state.middleName,
                "initial": this.state.initial,
                "gender": this.state.selectGender,
                "dob": moment(this.state.dobDate).format("YYYY-MM-DD"),
                "qualifications": this.state.qualification,
                "speciality": this.state.selectSpec,
                "mobile_number": parseInt(this.state.mobileNo),
                "email": this.state.emailID,
                "photo_url": this.state.photo_url,
                "digital_signature_key": "",
                "telemedicine_subscribed": this.state.telemedicine,
                "walk_in_subscribed": this.state.subWalk,
                "app_installed": this.state.appIns,
                "is_test_doctor": this.state.isDoc,
                "address_line_1": this.state.address1,
                "address_line_2": this.state.address2,
                "area_name": this.state.area,
                "pincode": this.state.pinCode,
                "city": this.state.cityName,
                "state": this.state.selectState,
                "country": this.state.selectCountry,
                "is_resigned": false,
                "resignation_date": null,
                "resignation_reason": "",
                "about_myself": this.state.aboutMyself,
            }
            ResetAPIService.create(data, Serviceurls.GREATE_DOC_LIST)
                .then(response => {
                    if (response.data.status == "success") {
                        this.props.history.goBack('/')
                    } else {
                        this.setState({
                            warningMsg: true,
                            warningMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    warningMsg: false
                                })
                            }, 2000);
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                errorMsg: false
                            })
                        }, 2000);
                    })
                })

        }
        catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMsg: false
                    })
                }, 2000);
            })
        }
    }

    getDoctorList = () => {
        try {
            ResetAPIService.getAll(Serviceurls.GET_DOCTOR_LIST)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            docList: response.data.data
                        })
                    } else {
                        this.setState({
                            warningMsg: true,
                            warningMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    warningMsg: false
                                })
                            }, 2000);
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                errorMsg: false
                            })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMsg: false
                    })
                }, 2000);
            })
        }
    }

    textBoxView = (label, width, value, name, error, helperext) => {
        return (
            <div>
                <div>
                    <TextField
                        id="outlined-basic"
                        sx={{ width: width }}
                        label={label}
                        value={value}
                        error={error}
                        helperText={helperext}
                        name={name}
                        multiline={label === "About Myself" ? true : false}
                        maxRows={4}
                        variant="outlined"
                        size='small'
                        inputProps={label === "Mobile Number" ? { maxLength: 10 } : label === "Registration No" ? {maxLength: 7} : ''}
                        onChange={label === "Mobile Number" ? this.numInput : this.textInput} />
                </div>
            </div>

        )
    }
    numInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            });
        }
    };
    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    renderCheckbox = (label, value, name) => {
        return (
            <div>
                <FormControlLabel control={<Checkbox checked={value} />} name={name} label={label} value={value}
                    onClick={(event) => {
                        var states = this.state
                        states[name] = event.target.checked
                        this.setState({ states })
                    }} />
            </div>
        )
    }
    renderGenderDropDown = (label, width) => {
        return (
            <div>
                <FormControl sx={{ width: width }} size='small'>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select
                        label={label}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectGender}
                        onChange={(event) => {
                            this.setState({
                                selectGender: event.target.value
                            })
                        }}
                        className="select-options-navbar-main-color"
                    >
                        <MenuItem value='m'>Male</MenuItem>
                        <MenuItem value='f'>Female</MenuItem>
                        <MenuItem value='t'>Other</MenuItem>
                    </Select>
                </FormControl>
            </div>
        )
    }
    renderSpecDropDown = (label, width) => {
        return (
            <div>
                <FormControl sx={{ width: width }} size='small'>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select
                        label={label}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectSpec}
                        onChange={(event) => {
                            this.setState({
                                selectSpec: event.target.value
                            })
                        }}
                        className="select-options-navbar-main-color"
                    >
                        {this.state.docList && this.state.docList.length > 0 && this.state.docList.map((list) => {
                            return (
                                <MenuItem value={list.role_id}>{list.role_comments}</MenuItem>
                            )
                        })}

                    </Select>
                </FormControl>
            </div>
        )
    }
    renderTextBox = () => {
        return (
            <div>
                <div>{this.textBoxView("Login ID", '15vw', this.state.loginID, "loginID",
                    this.state.loginIDerror, this.state.loginIDhelperText)}</div>
                <div className='emed_firstTextFiled'>{this.textBoxView("Password", '15vw', this.state.password, "password",
                    this.state.passworderror, this.state.passwordhelperText)}</div>
                <div className='emed_firstTextFiled'>{this.textBoxView("Registration No", '15vw', this.state.regNo, "regNo",
                    this.state.regNoerror, this.state.regNohelperText)}</div>

                <div className='emed_firstTextFiled_row'>
                    {this.textBoxView("First Name", '10vw', this.state.firstName, "firstName",
                        this.state.firstNameerror, this.state.firstNamehelperText)}
                    <div className='emed_firstRowTextbox'>{this.textBoxView("Middle Name", '11vw', this.state.middleName, "middleName",
                        this.state.middleNameerror, this.state.middleNamehelperText)}</div>
                    <div className='emed_firstRowTextbox'>{this.textBoxView("Initial", '8vw', this.state.initial, "initial",
                        this.state.initialerror, this.state.initialhelperText)}</div>
                </div>
                <div className='emed_firstTextFiled_row'>
                    {this.renderGenderDropDown("Gender", '15vw', this.state.gender, "gender",
                        this.state.gendererror, this.state.genderhelperText)}
                    <div className='emed_firstRowTextbox'><LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={this.state.dobDate}
                            name={"dobDate"}
                            inputFormat='DD-MM-YYYY'
                            onChange={
                                (date) => {
                                    var states = this.state
                                    states["dobDate"] = date
                                    this.setState({
                                        states
                                    });
                                }}
                            renderInput={(params) => <TextField style={{ width: '15vw', marginTop: '0.2vw' }} size='small' {...params} error={this.state.joinedDateerror} />}
                        />
                    </LocalizationProvider></div>
                </div>
                <div className='emed_firstTextFiled_row'>
                    {this.textBoxView("Qualification", '15vw', this.state.qualification, 'qualification',
                        this.state.qualificationerror, this.state.qualificationhelperText)}
                    <div className='emed_firstRowTextbox'>{this.renderSpecDropDown("Speciality", '15vw', this.state.speciality, "speciality",
                        this.state.specialityerror, this.state.specialityhelpertext)}</div>
                </div>
                <div className='emed_firstTextFiled_row'>
                    {this.textBoxView("Mobile Number", '15vw', this.state.mobileNo, "mobileNo",
                        this.state.mobileNoerror, this.state.mobileNohelperText)}
                    <div className='emed_firstRowTextbox'>{this.textBoxView("Email ID", '15vw', this.state.emailID, "emailID",
                        this.state.emailIDerror, this.state.emailIDhelperText)}</div>
                </div>
                {/* <div className='emed_firstTextFiled_row'>
                    {this.textBoxView("Photo URL", '15vw')}
                    <div className='emed_firstRowTextbox'> {this.textBoxView("Digital Signature", '15vw')}</div>
                </div> */}
            </div>
        )
    }

    selectCountry(val) {
        let states = this.state
        states["selectCountry"] = val
        this.setState({ states });
    }
    selectRegion(val) {
        let states = this.state
        states["selectState"] = val
        this.setState({ states });
    }
    renderTextBoxtwo = () => {
        const { selectCountry, selectState } = this.state;
        return (
            <div>
                <div className='emed_firstTextFiled'>{this.textBoxView('Address line 01', '15vw', this.state.address1, "address1",
                    this.state.address1error, this.state.address1helperText)}</div>
                <div className='emed_firstTextFiled'>{this.textBoxView('Address line 02', '15vw', this.state.address2, "address2",
                    this.state.address2error, this.state.address2helperText)}</div>
                <div className='emed_firstTextFiled'>{this.textBoxView('Area Name', '15vw', this.state.area, "area",
                    this.state.areaerror, this.state.areahelperText)}</div>
                <div className='emed_firstTextFiled'>{this.textBoxView('Pincode', '15vw', this.state.pinCode, "pinCode",
                    this.state.pinCodeerror, this.state.pinCodehelperText)}</div>
                <div className='emed_firstTextFiled'>
                    <CountryDropdown className='emed_Doctor_Country'
                        value={selectCountry}
                        onChange={(val) => this.selectCountry(val)}
                        priorityOptions={["IN"]}
                        whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                    />
                </div>
                <div className='emed_firstTextFiled'>
                    <RegionDropdown
                        className='emed_Doctor_Country'
                        country={selectCountry}
                        value={selectState}
                        onChange={(val) => this.selectRegion(val)} />
                </div>
                <div className='emed_firstTextFiled'>{this.textBoxView('City', '15vw', this.state.cityName, "cityName",
                    this.state.cityNameerror, this.state.cityNamehelperText)}</div>
            </div>
        )
    }
    renderTextBoxThree = () => {
        return (
            <div>
                <div>{this.renderCheckbox('Telemedicine Subscribed', this.state.telemedicine, "telemedicine")}</div>
                <div>{this.renderCheckbox('Wlak-in Subscribed', this.state.subWalk, "subWalk")}</div>
                <div>{this.renderCheckbox('App Installed', this.state.appIns, "appIns")}</div>
                <div>{this.renderCheckbox('Is Test Doctor', this.state.isDoc, "isDoc")}</div>
                {/* <div>{this.renderCheckbox('Is Resigned')}</div> */}
                {/* <div className='emed_firstTextFiled'>{this.textBoxView("Resignation Date")}</div> */}
                {/* <div className='emed_firstTextFiled'>{this.textBoxView("Resignation Reason")}</div> */}
                <div className='emed_firstTextFiled'>{this.textBoxView("About Myself", '15vw', this.state.aboutMyself, "aboutMyself")}</div>
            </div>
        )
    }

    saveBtn = () => {
        if (this.state.loginID.length > 0 &&
            this.state.password.length > 0 &&
            this.state.regNo.length > 0 &&
            this.state.firstName.length > 0 &&
            this.state.middleName.length > 0 &&
            this.state.initial.length > 0 &&
            this.state.mobileNo.length > 0 &&
            this.state.emailID.length > 0
        ) {

            this.postDoctorList()
        } else {
            this.validate()
        }
    }
    validate = () => {
        if (this.state.loginID === "") {
            this.setState({
                loginIDerror: true,
                loginIDhelperText: 'Please enter the LoginID'
            })
        } else {
            this.setState({
                loginIDerror: false,
                loginIDhelperText: ''
            })
        } if (this.state.password === "") {
            this.setState({
                passworderror: true,
                passwordhelperText: 'Please enter the password'
            })
        } else {
            this.setState({
                passworderror: false,
                passwordhelperText: ''
            })
        } if (this.state.regNo === "") {
            this.setState({
                regNoerror: true,
                regNohelperText: 'Please enter the regNo'
            })
        } else {
            this.setState({
                regNoerror: false,
                regNohelperText: ''
            })
        } if (this.state.firstName === "") {
            this.setState({
                firstNameerror: true,
                firstNamehelperText: 'Please enter the First Name'
            })
        } else {
            this.setState({
                firstNameerror: false,
                firstNamehelperText: ''
            })
        } if (this.state.middleName === "") {
            this.setState({
                middleNameerror: true,
                middleNamehelperText: 'Please enter the Middle Name'
            })
        } else {
            this.setState({
                middleNameerror: false,
                middleNamehelperText: ''
            })
        } if (this.state.initial === "") {
            this.setState({
                initialerror: true,
                initialhelperText: 'Please enter the Initial'
            })
        } else {
            this.setState({
                initialerror: false,
                initialhelperText: ''
            })
        } if (this.state.mobileNo === "") {
            this.setState({
                mobileNoerror: true,
                mobileNohelperText: 'Please enter the Mobile No'
            })
        } else {
            this.setState({
                mobileNoerror: false,
                mobileNohelperText: ''
            })
        } if (this.state.emailID === "") {
            this.setState({
                emailIDerror: true,
                emailIDhelperText: 'Please enter the Email ID'
            })
        } else {
            this.setState({
                emailIDerror: false,
                emailIDhelperText: ''
            })
        }
    }
    clearBtn = () => {
        this.setState({
            selectGender: '',
            selectSpec: '',
            selectCountry: 'India',
            selectState: 'Tamil Nadu',
            loginID: '',
            password: '',
            regNo: '',
            firstName: '',
            middleName: '',
            initial: '',
            dobDate: '',
            qualification: '',
            mobileNo: '',
            emailID: '',
            address1: '',
            address2: '',
            area: '',
            pinCode: '',
            cityName: '',
            telemedicine: false,
            subWalk: false,
            appIns: false,
            isDoc: false,
            aboutMyself: ''
        })
    }
    render() {
        console.log(this.state.clinicID)
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='emed_DoctorProfile_mainCon'>
                    <div className='emed_TopHeadNewClient'>
                        <div className='LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='Formlogo' />
                        </div>
                        <div className='emed_NewClientHeadTextCon'>
                            <p className='emed_NewClientHeadText'>Add Doctors Profile</p>
                        </div>
                    </div>
                    <div className='emed_DoctorDetails_Con'>
                        <div className='emed_DocDetail_oneCon'>
                            <div>
                                {this.renderTextBox()}
                            </div>
                        </div>
                        <div className='emed_DocDetail_twoCon'>
                            {this.renderTextBoxtwo()}
                        </div>
                        <div className='emed_DocDetail_threeCon'>
                            {this.renderTextBoxThree()}
                        </div>
                    </div>
                    <div className='emed_Doctor_BtnCon'>
                        <Stack spacing={2} direction='row'>
                            <Button variant='contained' onClick={() => { this.saveBtn() }}>Next</Button>
                            <Button variant='outlined' onClick={this.clearBtn}>Clear</Button>
                        </Stack>
                    </div>
                </div>
                {this.state.errorMsg ?
                    <ToastMsg
                        title={"Error:"}
                        msg={this.state.errorMsgText}
                        severity={"error"}
                    />
                    : null}
                {this.state.warningMsg ?
                    <ToastMsg
                        title={"Warning:"}
                        msg={this.state.warningMsgText}
                        severity={"warning"}
                    />
                    : null}
            </div>
        );
    }
}

export default DoctorProfile;