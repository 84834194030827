import React, { Component } from 'react';
import './SuperAdminPanel.css'
import Imagepath from '../../../Utility/ImagePath';
import {
    TextField,
    Button,
    Stack,
    InputAdornment,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Box,
    IconButton,
    Tooltip,
    ThemeProvider
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear'
import { DateRange } from 'react-date-range';
import { DateTime } from 'luxon'
import 'react-date-range/dist/styles.css'; // Date main style file
import 'react-date-range/dist/theme/default.css'; // Date theme css file
import ScrollArea from 'react-scrollbar';
import ToastMsg from '../../../CommonComponents/ToastMsg';
import GetAppIcon from '@mui/icons-material/GetApp';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import { localGetItem } from '../../../Utility/Serivce/CacheProviderService';
import moment from 'moment';



class SuperAdminPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchText: '',
            selectedBtn: 'all',
            userType: 'all',
            selectDate: false,
            selectionDate: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            },
            fromDate: '',
            toDate: '',
            tableData: [],
            userTableType: '',
            depName: [],
            userName: '',
            password: '',
            mobileNo: '',
            emailID: '',
            joinedDate: null,
            cliniData: [],
            clinicID: '',
            counsellorData: [],
            counsellorID: '',
            imgLabData: [],
            imgLabID: '',
            labData: [],
            labID: '',
            optomentryData: [],
            optomentryID: '',
            otData: [],
            otID: '',
            pharmacyData: [],
            pharmacyID: '',
            apiTabelData: [],
            doctorData: [],
            selectedIndex: '',
            isEdit: false,
            errorMsg: false,
            clinic_id: '',
            counsellor_id: '',
            img_lab_id: '',
            lab_id: '',
            optomentry_id: '',
            ot_id: '',
            pharmacy_id: '',
            isUserupdate: false,
            isUpdatemessage: '',
            orgDetail: {}

        }
    }

    componentDidMount() {
        const { location } = this.props
        if (location.state && location.state.btnArray && location.state.btnArray.length > 0) {
            var DeparmentName = location.state.btnArray
            this.setState({
                depName: DeparmentName,
                clinic_id: location.state.clinic_id != null ? location.state.clinic_id : null,
                counsellor_id: location.state.counsellor_id != null ? location.state.counsellor_id : null,
                img_lab_id: location.state.img_lab_id != null ? location.state.img_lab_id : null,
                lab_id: location.state.lab_id != null ? location.state.lab_id : null,
                optomentry_id: location.state.optomentry_id != null ? location.state.optomentry_id : null,
                ot_id: location.state.ot_id != null ? location.state.ot_id : null,
                pharmacy_id: location.state.pharmacy_id != null ? location.state.pharmacy_id : null
            })
        } else {
            this.setState({
                depName: []
            })
        }
        this.getUsersList()
        this.getOrganizationDetails()
    }

    getUsersList = () => {
        try {
            this.setState({ apiTabelData: [] })
            ResetAPIService.getAll(Serviceurls.USERS_GET_LIST +
                "?branch_id=" +
                localGetItem("BranchID") +
                "&department_type=" +
                this.state.selectedBtn +
                "&name=" +
                this.state.searchText +
                "&admin=" +
                this.state.userType +
                "&start_date=" +
                this.state.fromDate +
                "&end_date=" +
                this.state.toDate)
                .then(response => {
                    if (response.data.status == "success") {
                        var value = response.data.data
                        this.setState({
                            cliniData: value.clinic_obj.login_user,
                            clinicID: value.clinic_obj.clinic_id,
                            counsellorData: value.counsellor_obj.login_user,
                            counsellorID: value.counsellor_obj.counsellor_id,
                            imgLabData: value.img_lab_obj.login_user,
                            imgLabID: value.img_lab_obj.img_lab_id,
                            labData: value.lab_obj.login_user,
                            labID: value.lab_obj.lab_id,
                            optomentryData: value.optomentry_obj.login_user,
                            optomentryID: value.optomentry_obj.optomentry_id,
                            otData: value.ot_users.login_user,
                            otID: value.ot_users.ot_id,
                            pharmacyData: value.pharmacy_obj.login_user,
                            pharmacyID: value.pharmacy_obj.pharmacy_id,
                            doctorData: value.doctor_obj
                        }, () => {
                            var apiTabelData = []
                            if (this.state.cliniData && this.state.cliniData.length > 0) {
                                var apiTabelData = apiTabelData.concat(this.state.cliniData)
                            }
                            if (this.state.counsellorData && this.state.counsellorData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.counsellorData)
                            } if (this.state.imgLabData && this.state.imgLabData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.imgLabData)
                            }
                            if (this.state.labData && this.state.labData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.labData)
                            } if (this.state.optomentryData && this.state.optomentryData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.optomentryData)
                            } if (this.state.otData && this.state.otData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.otData)
                            } if (this.state.pharmacyData && this.state.pharmacyData.length > 0) {

                                var apiTabelData = apiTabelData.concat(this.state.pharmacyData)
                            }
                            if (this.state.doctorData && this.state.doctorData.length > 0) {
                                var { doctorData } = this.state
                                for (let i = 0; i < doctorData.length; i++) {
                                    apiTabelData.push(doctorData[i].login_user)
                                }
                            }
                            for (let i = 0; i < apiTabelData.length; i++) {
                                apiTabelData[i]['isEdit'] = true
                            }
                            this.setState({
                                apiTabelData: apiTabelData
                            })
                        })
                    } else {
                        this.setState({
                            warningMsg: true,
                            warningMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    warningMsg: false
                                })
                            }, 2000);
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                errorMsg: false
                            })
                        }, 2000);
                    })
                })
        }
        catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMsg: false
                    })
                }, 2000);
            })
        }
    }

    getOrganizationDetails = () => {
        try {
            ResetAPIService.getAll(Serviceurls.GET_ORGANIZATION_DETAILS + "?branch_id=" + localGetItem("BranchID"))
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            orgDetail: response.data.data
                        })
                    } else {
                        this.setState({
                            warningMsg: true,
                            warningMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    warningMsg: false
                                })
                            }, 2000);
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                errorMsg: false
                            })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        errorMsg: false
                    })
                }, 2000);
            })
        }
    }



    btnClick = (name) => {
        // var states = this.state
        // for(let i=0; i < states.apiTabelData.length; i++){
        //     if(states.apiTabelData[i].user_id){

        //     }else{
        //         states.apiTabelData[i].user_name = ''
        //         states.apiTabelData[i].password = ""
        //         states.apiTabelData[i].mobile_no = ""
        //         states.apiTabelData[i].email_id = ''
        //         states.apiTabelData[i].joined_date = ''
        //         states.apiTabelData[i].user_type = ''
        //     }
        // }
        this.setState({
            selectedBtn: name,
            // states
        }, () => {
            this.getUsersList()
        })
    }

    renderBtn = (name) => {
        return (
            <div style={{ display: "flex", flexDirection: 'row' }}>
                {this.state.depName && this.state.depName.map((item) => {
                    return (
                        <Stack style={{ marginRight: '1vw' }} spacing={2} direction="row">
                            <Button className='TabBtn' size='small' variant={this.state.selectedBtn === item ? "contained" : "outlined"}
                                onClick={() => { this.btnClick(item) }}>{item}</Button>
                        </Stack>
                    )
                })}

            </div>
        )
    }

    renderTableHead = () => {
        return (
            <div>
                <div className='emed_PanelTableHead'>
                    <div className='emed_PanelHeadText1'><p>S.No</p></div>
                    <div className='emed_PanelHeadText2'><p>User Name</p></div>
                    <div className='emed_PanelHeadText2'><p>Password</p></div>
                    <div className='emed_PanelHeadText2'><p>Mobile No</p></div>
                    <div className='emed_PanelHeadText5'><p>Email Id</p></div>
                    <div className='emed_PanelHeadText2'><p>Joined Date</p></div>
                    <div className='emed_PanelHeadText5'><p>User Type</p></div>
                    <div className='emed_PanelHeadText2'><p>Action</p></div>
                </div>
            </div>
        )
    }
    saveBtn = (index, item) => {

        try {
            var index = index;
            var data = this.state.apiTabelData[index];
            var { apiTabelData } = this.state

            for (let i = 0; i < apiTabelData.length; i++) {
                var date = moment(apiTabelData[i].joined_date).format('YYYY-MM-DD')
                var data = {
                    "user_name": apiTabelData[i].user_name,
                    "password": apiTabelData[i].password,
                    "mobile_no": parseInt(apiTabelData[i].mobile_no),
                    "email_id": apiTabelData[i].email_id,
                    "joined_date": date,
                    "user_type": apiTabelData[i].user_type
                }
                if (this.state.clinic_id != null && this.state.selectedBtn === "fo") {
                    data["clinic_id"] = this.state.clinic_id
                } if (this.state.lab_id != null && this.state.selectedBtn === "lab") {
                    data["lab_id"] = this.state.lab_id
                } if (this.state.counsellor_id != null && this.state.selectedBtn === "counsellor") {
                    data["counsellor_id"] = this.state.counsellor_id
                } if (this.state.img_lab_id != null && this.state.selectedBtn === "img_lab") {
                    data["img_lab_id"] = this.state.img_lab_id
                } if (this.state.optomentry_id != null && this.state.selectedBtn === "optomentry") {
                    data["optomentry_id"] = this.state.optomentry_id
                } if (this.state.ot_id != null && this.state.selectedBtn === 'ot') {
                    data["ot_id"] = this.state.ot_id
                } if (this.state.pharmacy_id != null && this.state.selectedBtn === "pharmacy") {
                    data["pharmacy_id"] = this.state.pharmacy_id
                }
            }
            var dateformet
            if (typeof (item.joined_date) != 'string') {
                dateformet = item.joined_date.format('YYYY-MM-DD')
            } else {
                dateformet = item.joined_date;
            }
            var putData = {
                "user_id": item.user_id,
                "user_name": item.user_name,
                "mobile_no": parseInt(item.mobile_no),
                "email_id": item.email_id,
                "joined_date": dateformet,
                "admin_access": item.user_type
            }
            this.state.isEdit ?

                ResetAPIService.update(putData, Serviceurls.USER_LIST_UPDATE)
                    .then(response => {
                        if (response.data.status == "success") {
                            this.setState({
                                isUserupdate: true,
                                isUpdatemessage: response.data.message,
                                selectedIndex: ""
                            }, () => {
                                setTimeout(() => {
                                    this.setState({ isUserupdate: false })
                                }, 2000);
                            })
                            this.getUsersList()
                        }
                    }).catch(e => {
                        this.setState({
                            errorMsg: true,
                            errorMsgText: e.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ errorMsg: false })
                            }, 2000);

                        })
                    })
                : ResetAPIService.create(data, Serviceurls.CREATE_USER_LIST)
                    .then(response => {
                        if (response.data.status == 'success') {
                            if (apiTabelData[index] != '') {
                                apiTabelData[index].user_name = ''
                                apiTabelData[index].password = ''
                                apiTabelData[index].mobile_no = ''
                                apiTabelData[index].email_id = ''
                                apiTabelData[index].joined_date = null
                                apiTabelData[index].user_type = ''
                            }
                            this.setState({
                                user_id: response.data.user_id,

                            })
                            this.getUsersList()
                        }
                    }).catch(e => {
                        this.setState({
                            errorMsg: true,
                            errorMsgText: e.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ errorMsg: false })
                            }, 2000);
                        })
                    })
        } catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 2000);
            })
        }
    }

    editBtn = (index) => {
        this.setState({
            selectedIndex: index,
            isEdit: true,
        })
    }
    renderTableData = () => {
        return (
            <div>
                <ScrollArea className='emed_TableScrollCon' horizontal={false}>
                    {this.state.apiTabelData && this.state.apiTabelData.map((item, index) => {
                        var Sno = index + 1
                        return (
                            <div className='emed_PanelTableData'>
                                <div className='emed_PanelHeadText1'>{Sno}</div>
                                <div className='emed_PanelHeadText2'>{this.renderTextInput("User Name", 'text', '10vw', item.user_name, "user_name",
                                    this.state.userNameerror, index, item.user_id, item.isEdit)}</div>
                                <div className='emed_PanelHeadText2'>{this.renderTextInput("Pass word", 'password', '10vw', item.password, "password",
                                    this.state.passworderror, index, item.user_id, item.isEdit)}</div>
                                <div className='emed_PanelHeadText2'>{this.renderTextInput("Mobile No", 'text', '10vw', item.mobile_no, "mobile_no",
                                    this.state.mobileNoerror, index, item.user_id, item.isEdit)}</div>
                                <div className='emed_PanelHeadText5'>{this.renderTextInput("Email ID", 'txet', '13vw', item.email_id, "email_id",
                                    this.state.emailIDerror, index, item.user_id, item.isEdit)}</div>
                                <div className='emed_PanelHeadText2'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={item.joined_date}
                                            readOnly={this.state.selectedIndex === index || !item.isEdit ? false : true}
                                            name={"joined_date"}
                                            inputFormat='DD-MM-YYYY'
                                            views={["year", "month", "day"]}
                                            onChange={
                                                (date) => {
                                                    var { apiTabelData } = this.state
                                                    apiTabelData[index]["joined_date"] = date
                                                    var start = moment(date).format('yyyy-MM-dd')
                                                    this.setState({
                                                        start
                                                    });
                                                }}
                                            renderInput={(params) => <TextField style={{ width: '10vw', marginTop: '0.2vw' }} size='small' {...params} error={this.state.joinedDateerror} />}
                                        />
                                    </LocalizationProvider></div>
                                <div className='emed_PanelHeadText5'>
                                    <div>
                                        {/* <Box sx={{ minWidth: '7vw' }}> */}
                                        <FormControl sx={{ width: '13vw', marginTop: '0.2vw' }} size='small'>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={item.user_type}
                                                error={this.state.userTableTypeerror}
                                                onChange={(e) => this.tableUserType(e, index)}
                                                className="select-options-navbar-main-color"
                                                inputProps={{ readOnly: this.state.selectedIndex === index || !item.isEdit ? false : true }}
                                            >
                                                <MenuItem value={false}>User</MenuItem>
                                                <MenuItem value={true}>Admin</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* </Box> */}
                                    </div>
                                </div>
                                <div style={{ width: '12vw', display: 'flex', alignItems: 'center' }}>
                                    <Button variant="contained" size='small' color={this.state.selectedIndex === index || !item.isEdit ? "success" : "error"} onClick={() => {
                                        if (item.user_id && this.state.isEdit == false) {
                                            this.editBtn(index, item)
                                        } else {
                                            this.saveBtn(index, item)
                                        }
                                    }}>
                                        {this.state.selectedIndex === index || !item.isEdit ? 'Save' : 'Edit'}
                                    </Button></div>

                            </div>
                        )
                    })}
                </ScrollArea>
            </div>
        )
    }
    renderTextInput = (placeholder, type, width, value, name, error, index, id, isEdit) => {
        return (
            <div style={{ margin: '0.2vw' }}>
                <TextField
                    disabled={type === "password" && id ? true : false}
                    size='small'
                    placeholder={placeholder}
                    name={name}
                    type={isEdit && type === "password" ? "password" : "text"}
                    error={error}
                    variant="outlined"
                    value={value ? value : ""}
                    sx={{ width: width }}
                    inputProps={{
                        readOnly: this.state.selectedIndex === index || !isEdit ? false : true,
                        maxLength: name === "mobile_no" ? 10 : ''
                    }}
                    onChange={(e) => { name === "mobile_no" ? this.numInput(e, index) : this.textInput(e, index) }} />
            </div>
        )
    }
    textInput = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        var { apiTabelData } = this.state
        apiTabelData[index][name] = value
        // states[name] = value
        this.setState({
            apiTabelData
        });
    }
    numInput = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        var { apiTabelData } = this.state
        apiTabelData[index][name] = value
        const re = /^[0-9]*$/;
        if (apiTabelData[index][name] === "" || re.test(apiTabelData[index][name])) {
            this.setState({
                apiTabelData,
            });
        }
    };
    selectUserType = (event) => {
        this.setState({
            userType: event.target.value
        }, () => {
            this.getUsersList()
        })
    }
    tableUserType = (event, index) => {
        var { apiTabelData } = this.state
        apiTabelData[index]["user_type"] = event.target.value
        this.setState({
            apiTabelData
        })
    }
    ClearValue = () => {
        this.setState({
            fromDate: '',
            toDate: '',
            userType: 'all'
        }, () => this.getUsersList())
    }
    addUser = () => {
        try {
            var keys = {
                "user_name": '',
                "mobile_no": '',
                "email_id": '',
                "joined_date": '',
                "user_type": '',
                "password": ''
            }
            var { apiTabelData } = this.state;
            var branch_id = localGetItem("BranchID");
            ResetAPIService.get("?branch_id=" + branch_id, Serviceurls.GET_USER_LIMIT)
                .then((response) => {
                    if (response.data.status == "success") {
                        if (apiTabelData.length >= response.data.users_limit) {
                            this.setState({
                                errorMsg: true,
                                errorMsgText: response.data.message
                            }, () => {
                                setTimeout(() => {
                                    this.setState({ errorMsg: false })
                                }, 2000);
                            })
                        } else {
                            apiTabelData.push(keys)
                        }
                        this.setState({
                            apiTabelData,
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsg: false })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.response.data.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 2000);
            })
        }
    }
    searchTextBox = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => { this.getUsersList() });
    }
    render() {
        var OrgDetails = this.state.orgDetail
        return (
            <div>
                <div className='HeadViewPanel'>
                    <div className='HeadImgs'>
                        <div className='BackBtnIcon' onClick={() => this.props.history.goBack('/')}>
                            <img src={Imagepath.Back} alt='Back' />
                        </div>
                        <div>
                            <img src={Imagepath.Logo} alt='Logo' className='PanelLogo' />
                        </div>
                    </div>
                    <div>
                        <p className='AdminPanelText'>Super Admin Panel</p>
                    </div>
                    <div className='LogoutBtn'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <hr />
                <div className='OrgDetailsCon'>
                    <div className='OrgDetailsRow'>
                        <div>
                            <div className='OrgnameTextCon'>
                                <p className='OrgnameText'>Oraganization Name: </p>
                                <p className='OrgnameText1'>{OrgDetails.organization_name && OrgDetails.organization_name ? OrgDetails.organization_name : '--'}</p>
                            </div>
                            <p className='OrgDetilText'>{OrgDetails.address_1 && OrgDetails.address_1 ? OrgDetails.address_1 : '--'}</p>
                            <p className='OrgDetilText'>{OrgDetails.address_2 && OrgDetails.address_2 ? OrgDetails.address_2 : '--'}</p>
                            <div className='OrgaddressCon'>
                                <div className='OrgaddressTextCon'>
                                    <p className='OrgbrnachText'>Branch: </p>
                                    <p className='OrgbranchText1'>{OrgDetails.branch_name && OrgDetails.branch_name ? OrgDetails.branch_name : '--'}</p>
                                </div>
                                <div className='OrgaddressTextCon'>
                                    <p className='OrgpinText'>Pin Code: </p>
                                    <p className='OrgpinText1'>{OrgDetails.pincode && OrgDetails.pincode ? OrgDetails.pincode : '--'}</p>
                                </div>
                                <div className='OrgaddressTextCon'>
                                    <p className='OrgbrnachText'>Landmark: </p>
                                    <p className='OrgbranchText1'>{OrgDetails.landmark && OrgDetails.landmark ? OrgDetails.landmark : '--'}</p>
                                </div>
                            </div>
                        </div>
                        <div className='DetailsImg'>
                            <img src={Imagepath.Logo} alt='Logo' className='PanelLogo' />
                        </div>
                    </div>
                    <hr />
                    <div>
                        {this.renderBtn()}
                    </div>
                    <hr />
                    <div className='emed_FilterCon'>
                        <div className='emed_FilterBoxs'>
                            <div>
                                <TextField id="outlined-basic3" placeholder='Search items'
                                    value={this.state.searchText}
                                    variant="outlined" size='small'
                                    name={"searchText"}
                                    onChange={this.searchTextBox}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'><SearchIcon style={{ color: '#888888' }} /></InputAdornment>
                                        )
                                    }} />
                            </div>
                            <div>
                                <Box sx={{ minWidth: '10vw', marginLeft: '1vw' }}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>User Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.userType}
                                            label="User Type"
                                            onChange={this.selectUserType}
                                            className="select-options-navbar-main-color"
                                        >
                                            <MenuItem value='all'>All</MenuItem>
                                            <MenuItem value='false'>User</MenuItem>
                                            <MenuItem value='true'>Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            <div className='emed_DateCon'>
                                <TextField
                                    id="outlined-basic1"
                                    placeholder='Joined date'
                                    variant="outlined"
                                    size='small'
                                    value={(this.state.fromDate ? "(" + this.state.fromDate + " ~ " : "") + (this.state.toDate ? this.state.toDate + " ) " : "")}
                                    onClick={() => this.setState({ selectDate: true })}
                                    // onBlur={() => this.setState({ selectDate: false })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'><CalendarMonthIcon style={{ color: '#888888' }} /></InputAdornment>
                                        )
                                    }} />
                                {this.state.selectDate ?
                                    <div style={{ zIndex: 4 }}>
                                        <DateRange
                                            ranges={[this.state.selectionDate]}
                                            onChange={(date) => {
                                                this.setState({
                                                    selectionDate: date.selection
                                                })
                                                if (date.selection.startDate != date.selection.endDate) {
                                                    var start = DateTime.fromJSDate(date.selection.startDate).toFormat('yyyy-MM-dd')
                                                    var end = DateTime.fromJSDate(date.selection.endDate).toFormat('yyyy-MM-dd')
                                                    this.setState({
                                                        fromDate: start,
                                                        toDate: end,
                                                        selectDate: false
                                                    }, () => this.getUsersList())
                                                }
                                            }}
                                            moveRangeOnFirstSelection={false}
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div>
                                <IconButton color="primary" aria-label="upload picture" component="label"
                                    onClick={this.ClearValue}>
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Stack spacing={2} direction="row">
                                <Tooltip title={"Export"} placement="top">
                                    <Button variant="outlined" size='small'><GetAppIcon /></Button>
                                </Tooltip>
                                <Button disabled={this.state.selectedBtn === "all" ? true : false} variant="contained" size='small'
                                    onClick={() => { this.state.selectedBtn === "doctor" ? this.props.history.push({ pathname: '/Home/NewHospital/DoctorProfile', state: this.state }) : this.addUser() }}
                                >{this.state.selectedBtn === "doctor" ? "Add New Doctor" : "Add New User"}</Button>
                            </Stack>
                        </div>
                    </div>
                    <div>
                        {this.renderTableHead()}
                        {this.renderTableData()}
                    </div>
                    <div className='eMed_GoHomeBtn'>
                        <Button variant='contained' onClick={()=> {this.props.history.push("/Home")}}>Go Home</Button>
                    </div>
                    {this.state.userNameerror ||
                        this.state.passworderror ||
                        this.state.mobileNoerror ||
                        this.state.emailIDerror ||
                        this.state.joinedDateerror ||
                        this.state.userTableTypeerror ?
                        <ToastMsg
                            title={"Warning:"}
                            msg={"Please enter the all details"}
                            severity={"warning"} />
                        : null}
                </div>
                {this.state.errorMsg ?
                    <ToastMsg
                        title={"Error:"}
                        msg={this.state.errorMsgText}
                        severity={"error"}
                    />
                    : null
                }
                {this.state.isUserupdate ?
                    <ToastMsg
                        title={"Success:"}
                        msg={this.state.isUpdatemessage}
                        severity={"success"}
                    />
                    : null
                }
                {this.state.warningMsg ?
                    <ToastMsg
                        title={"Warning:"}
                        msg={this.state.warningMsgText}
                        severity={"warning"}
                    />
                    : null
                }
            </div>
        );
    }
}

export default SuperAdminPanel;