import React, { Component } from 'react';
import Imagepath from '../Utility/ImagePath';
import './Home.css'
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

class Home extends Component {
    render() {
        return (
            <div>
                <div className='LogoutConHome'>
                    <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                        Logout
                    </Button>
                </div>
                <div className='LogoCon'>
                    <img src={Imagepath.Logo} alt={'Logo'} className='Logo' />
                </div>
                <div className='Card'>
                    <div className='CardRow'>
                        <div className='NewHospital' onClick={() => this.props.history.push('/Home/NewHospital')}>
                            <div className='CardImg'>
                                <img src={Imagepath.NewHospital} alt={'New Hispital'} className='HospitalLogo' />
                            </div>
                            <p className='CredText'>New Hospital</p>
                        </div>
                        <div className='NewHospital' onClick={() => this.props.history.push("/Home/Organization")}>
                            <div className='CardImg'>
                                <img src={Imagepath.ExistingHospital} alt={'Existing Hopital'} className='HospitalLogo' />
                            </div>
                            <p className='CredText'>Existing Hospital</p>
                        </div>
                    </div>
                </div>
                <div className='Card'>
                    <div className='CardRow'>
                        <div className='NewHospital' onClick={() => this.props.history.push("/ComingSoon")}>
                            <div className='CardImg'>
                                <img src={Imagepath.Removal} alt={'Existing Hopital'} className='HospitalLogo' />
                            </div>
                            <p className='CredText'>Removal</p>
                        </div>
                        <div className='NewHospital' onClick={() => this.props.history.push("/ComingSoon")}>
                            <div className='CardImg'>
                                <img src={Imagepath.TrialVersion} alt={'Existing Hopital'} className='HospitalLogo' />
                            </div>
                            <p className='CredText'>Trail Version</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;