import React, { Component } from 'react';
import './AddBranch.css';
import Imagepath from '../../../Utility/ImagePath';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

class AddBranch extends Component {
    constructor(props) {
        super(props)

    }

    renderCard = (image, name, label) => {
        const { history } = this.props
        return (
            <div className='AddBranchCrad' onClick={() => this.props.history.push('/Home/NewHospital/AddBranchDetails')}>
                <div className='BranchImg'>
                    <img src={image} alt={name} className='AddLogo' />
                </div>
                <div className='BranchName'>
                    <p>{label}</p>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div>
                <div className='LogoutConHome'>
                    <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                        Logout
                    </Button>
                </div>
                <div className='BranchMainCon'>
                    <div>
                        <div className='emed_AddBranchLogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='Formlogo' />
                        </div>
                        <div className='CardView'>
                            {this.renderCard(Imagepath.AddBranch, "AddBrnach", "Add Branch")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBranch;