import React, { Component } from 'react';
import './Signin.css'
import { TextField, Button, InputAdornment, getTableRowUtilityClass } from '@mui/material';
import Imagepath from '../Utility/ImagePath';
import ResetAPIService from '../Utility/Serivce/ResetAPIService'
import { Serviceurls } from '../Utility/API/ServiceUrls';
import { localSetItem } from '../Utility/Serivce/CacheProviderService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToastMsg from '../CommonComponents/ToastMsg';
class SignIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            passWord: '',
            showPassword: false,
            isLogined: false,
            isLoginerror: false,
            errorMsg: '',
            successMsg: ''
        }
    }

    signin = () => {
        try {
            var data = {
                "username": this.state.userName,
                "password": this.state.passWord
            }
            ResetAPIService.create(data, Serviceurls.LOGIN_EMEDHUB_ADMIN)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        localSetItem("AccessToken", data.token)
                        this.setState({
                            isLogined: true,
                            successMsg: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.props.history.push('/Home')
                                this.setState({
                                    isLogined: false,
                                })
                            }, 2000);
                        })

                    } else {
                        this.setState({
                            isLoginerror: true,
                            successMsg: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({
                                    isLoginerror: false,
                                })
                            }, 2000);
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        isLoginerror: true,
                        errorMsg: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                isLoginerror: false,
                            })
                        }, 2000);
                    })
                })

        }
        catch (e) {
            this.setState({
                isLoginerror: true,
                errorMsg: e.response.data.message
            }, () => {
                setTimeout(() => {
                    this.setState({
                        isLoginerror: false,
                    })
                }, 1500);
            })
        }
    }


    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [event.target.name]: value
        });
    }
    Visibility = () => {
        this.setState({
            showPassword: this.state.showPassword ? false : true,
        });
    }

    render() {
        const { history } = this.props;
        return (
            <div>
                <div className='LoginPage'>
                    <div>
                        <img src={Imagepath.Logo} alt={'Logo'} className="logo" />

                    </div>
                    <div className='LoginPageContainer'>
                        <div className='loginTextBox'>
                            <TextField
                                id="outlined-basic"
                                label="User Name"
                                variant="outlined"
                                size='small'
                                value={this.state.userName}
                                name="userName"
                                onChange={this.textInput}
                                sx={{ width: "14.5vw" }}
                                focused />
                        </div>
                        <div className='loginTextBox'>
                            <TextField
                                id="outlined-basic"
                                type={this.state.showPassword ? "text" : "password"}
                                label="Password"
                                variant="outlined"
                                size='small'
                                value={this.state.passWord}
                                name="passWord"
                                onChange={this.textInput}
                                sx={{ width: "14.5vw" }}
                                focused
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className="eMed_signin_visibleIcon">
                                            <VisibilityIcon onClick={this.Visibility} />
                                        </InputAdornment>
                                    )
                                }} />
                        </div>
                        <div className='loginBtn'>
                            <Button variant="contained" onClick={() => this.signin()}>Find User</Button>
                            {this.state.isLogined ?
                                <ToastMsg
                                    title={"Success:"}
                                    msg={this.state.successMsg}
                                    severity={"success"}
                                />
                                : null}
                            {this.state.isLoginerror ?
                                <ToastMsg
                                    title={"Error:"}
                                    msg={this.state.errorMsg}
                                    severity={"error"}
                                />
                                : null}
                        </div>
                        <div className='AddnewUser'>
                            <p><a href='Add New User'>Add New User</a></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignIn;