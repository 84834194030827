import React, { Component } from 'react';
import './AddBranch.css'
import Imagepath from '../../../Utility/ImagePath';
import { TextField, Button, Stack, MenuItem, Select, FormControl } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import { DateTime } from 'luxon'
import { localGetItem, localSetItem } from '../../../Utility/Serivce/CacheProviderService';
import ToastMsg from '../../../CommonComponents/ToastMsg';
import moment from 'moment';

class AddBranchDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branchName: '',
            phoneNo: '',
            address1: '',
            address2: '',
            cityName: '',
            stateName: 'Tamil Nadu',
            countryName: 'India',
            pinCode: '',
            GSTno: '',
            loginID: '',
            password: '',
            comPeried: '',
            startDate: null,
            InsAmount: '',
            noUsers: '',
            subAmount: '',
            renualDate: null,
            document1: '',
            department: '',
            uploadImage: '',
            error: false,
            helperText: '',
            userTableType: '',
            errorMsg: false,
            errorMsgText: '',
            warMsg: false,
            warnMsgText: "",
            emailID: ''

        }
    }

    postBranchDetails = () => {
        var startDate = moment(this.state.startDate).format("YYYY-MM-DD")
        var renualDate = this.state.renualDate ? moment(this.state.renualDate).format("YYYY-MM-DD") : null
        try {
            var org = localGetItem("OrgID")
            var data = {
                "organization_id": org,
                "login_id": this.state.loginID,
                "password": this.state.password,
                "branch_name": this.state.branchName,
                "phone_no": this.state.phoneNo,
                "address_1": this.state.address1,
                "address_2": this.state.address2,
                "city": this.state.cityName,
                "state": this.state.stateName,
                "country": this.state.countryName,
                "pin_code": this.state.pinCode,
                "email": this.state.emailID,
                "gst_no": this.state.GSTno,
                "complementry_period": this.state.comPeried,
                "start_date": startDate,
                "installation_amount": JSON.parse(this.state.InsAmount),
                "no_of_users": JSON.parse(this.state.noUsers),
                "subscription_amount": JSON.parse(this.state.subAmount),
                "renewal_date": renualDate,
                "document_1": "",
                "document_2": "",
                "document_3": ""
            }
            ResetAPIService.create(data, Serviceurls.BRANCH_LIST_POST)
                .then(response => {
                    if (response.data.status == "success") {
                        localSetItem("BranchID", response.data.branch_id)
                        this.props.history.push('/Home/NewHospital/Deparment')
                    } else {
                        this.setState({
                            warMsg: true,
                            warnMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ warMsg: false })
                            }, 2000);
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsg: false })
                        }, 2000);
                    })
                })
        }
        catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 2000);
            })
        }
    }

    UploadImage = (event) => {
        this.setState({
            uploadImage: event.target.result
        })
    }
    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    numInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            });
        }
    };
    tableUserType = (event) => {
        this.setState({
            userTableType: event.target.value
        })
    }

    selectCountry(val) {
        let states = this.state
        states["countryName"] = val
        this.setState({ states });
    }
    selectRegion(val) {
        let states = this.state
        states["stateName"] = val
        this.setState({ states });
    }
    renderTextBox = (label, placeholder, value, name, error, helperText) => {
        var { countryName, stateName } = this.state
        return (
            <div>
                <div className='TextBoxContainer'>
                    <div className='TextLabel'>
                        <label>{label}:</label>
                    </div>
                    {label === "Start Date" || label === "Reneual Date" ?
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    placeholder={placeholder}
                                    value={value}
                                    name={name}
                                    inputFormat="DD/MM/YYYY"
                                    onChange={
                                        (date) => {
                                            var states = this.state
                                            states[name] = date
                                            if (name === "renualDate") {
                                                this.setState({
                                                    states
                                                });
                                            } else {
                                                this.setState({
                                                    states
                                                })
                                            }

                                        }
                                    }
                                    renderInput={(params) => <TextField style={{ width: '14.5vw', marginLeft: '-0.4vw' }} size='small' {...params} error={error}
                                        helperText={helperText} />}
                                />
                            </LocalizationProvider>
                        </div> :
                        label === "Country" ?
                            <CountryDropdown className='emed_addBranch_Country'
                                style={{ borderColor: this.state.countryName === "" ? "red" : '#bbb9b9' }}
                                value={countryName}
                                onChange={(val) => this.selectCountry(val)}
                                priorityOptions={["IN"]}
                                whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                            /> :
                            label === "State" ?
                                <RegionDropdown
                                    className='emed_addBranch_Country'
                                    country={countryName}
                                    value={stateName}
                                    onChange={(val) => this.selectRegion(val)} /> :
                                label === "Document 01" || label === "Document 02" || label === "Document 03" ?
                                    <div className='TextInputbox'>
                                        <TextField
                                            id="outlined-basic"
                                            style={{ width: '14.5vw' }}
                                            placeholder={placeholder}
                                            variant="outlined"
                                            size='small'
                                            type={"file"}
                                            accept="image/*"
                                            onClick={(event) => {
                                                this.setState({ uploadImage: event.target.value })
                                            }} />
                                        {/* <input type="file" accept='image/*' onClick={(event)  => {
                                this.setState({uploadImage: event.target.value})
                                }}/> */}
                                        {label === "Add Logo" ?

                                            <p className='HelperText'>Supported Format PNG,JPG &amp; JPEG</p> : null}
                                    </div> :
                                    label === "Department" ?
                                        <FormControl sx={{ width: '14.5vw', marginLeft: '-.5vw' }} size='small'>
                                            <Select
                                                error={error}
                                                helperText={helperText}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.userTableType}
                                                onChange={this.tableUserType}
                                                className="select-options-navbar-main-color"
                                            >
                                                <MenuItem value='all'>All</MenuItem>
                                                <MenuItem value='user'>User</MenuItem>
                                                <MenuItem value='admin'>Admin</MenuItem>
                                                <MenuItem value='superadmin'>Super Admin</MenuItem>
                                            </Select>
                                            <helperText className='emed_Addbench_helperText'>{helperText}</helperText>
                                        </FormControl> :
                                        <div className='TextInputbox1'>
                                            <TextField
                                                id="outlined-basic"
                                                style={{ width: '14.5vw' }}
                                                placeholder={placeholder}
                                                variant="outlined"
                                                size='small'
                                                value={value}
                                                error={error}
                                                inputProps={label === "Phone No" ? { maxLength: 10 } : ''}
                                                helperText={helperText}
                                                name={name}
                                                onChange={name === "phoneNo" ||
                                                    name === "InsAmount" ||
                                                    name === "noUsers" ||
                                                    name === "subAmount" ? this.numInput : this.textInput}
                                            />
                                        </div>
                    }


                </div>
            </div>
        )
    }
    renderTextBoxCon = () => {
        return (
            <div className='FormTextBoxCon'>
                <div className='emed_branchFormTextrow1'>
                    {this.renderTextBox("Branch Name", "Add Name", this.state.branchName, "branchName",
                        this.state.branchNameerror, this.state.branchNameHelperText)}
                    {this.renderTextBox("Phone No", "Add Mobile No", this.state.phoneNo, "phoneNo",
                        this.state.phoneNoerror, this.state.phoneNoHelperText)}
                    {this.renderTextBox("Email ID", "Add Email No", this.state.emailID, "emailID",
                        this.state.emailIDerror, this.state.emailIDHelperText)}
                    {this.renderTextBox("Address 01", "Add Address", this.state.address1, "address1",
                        this.state.address1error, this.state.address1HelperText)}
                    {this.renderTextBox("Address 02", "Add Address 02", this.state.address2, "address2")}
                    {this.renderTextBox("Country", "Add Country", this.state.countryName, "countryName",
                        this.state.countryNameerror, this.state.countryNameHelperText)}
                    {this.renderTextBox("State", "Add State", this.state.stateName, "stateName",
                        this.state.stateNameerror, this.state.stateNameHelperText)}



                </div>
                <div className='emed_branchForm'>
                    {this.renderTextBox("City", "Add City", this.state.cityName, "cityName",
                        this.state.cityNameerror, this.state.cityNameHelperText)}
                    {this.renderTextBox("Pin Code", "Add Pin Code", this.state.pinCode, "pinCode",
                        this.state.pinCodeerror, this.state.pinCodeHelperText)}
                    {this.renderTextBox("GST No", "Add GST No", this.state.GSTno, "GSTno",
                        this.state.GSTnoerror, this.state.GSTnoHelperText)}
                    {this.renderTextBox("Login ID", "Enter Login ID", this.state.loginID, "loginID",
                        this.state.loginIDerror, this.state.loginIDHelperText)}
                    {this.renderTextBox("Password", "Create Password", this.state.password, "password",
                        this.state.passworderror, this.state.passwordHelperText)}
                    {this.renderTextBox("Complementry Period", "Add Complementry Period", this.state.comPeried, "comPeried",
                        this.state.comPeriederror, this.state.comPeriedHelperText)}
                    {this.renderTextBox("Start Date", "Enter Date", this.state.startDate, "startDate",
                        this.state.startDateerror, this.state.startDateHelperText)}
                </div>
                <div className='emed_branchForm1'>
                    {this.renderTextBox("Installiation Amount", "Enter Amount", this.state.InsAmount, "InsAmount",
                        this.state.InsAmounterror, this.state.InsAmountHelperText)}
                    {this.renderTextBox("No of Users", "Add No of Usres", this.state.noUsers, "noUsers",
                        this.state.noUserserror, this.state.noUsersHelperText)}
                    {this.renderTextBox("Subcribtion Amount", "Enter Amount", this.state.subAmount, "subAmount",
                        this.state.subAmounterror, this.state.subAmountHelperText)}
                    {this.renderTextBox("Reneual Date", "Select Date", this.state.renualDate, "renualDate",
                        this.state.renualDateerror, this.state.renualDateHelperText)}
                    {/* {this.renderTextBox("Document 01", "Upload Your File", this.state.document1, "document1")}
                    {this.renderTextBox("Document 02", "Upload Your File")}
                    {this.renderTextBox("Document 03", "Upload Your File")}
                    {this.renderTextBox("Department", "select specialization", this.state.userTableType, "userTableType",
                        this.state.userTableTypeerror, this.state.userTableTypeHelperText)} */}

                </div>
            </div>
        )
    }
    Submit = () => {
        if (this.state.branchName.length > 0 &&
            this.state.phoneNo.length > 0 &&
            this.state.address1.length > 0 &&
            this.state.cityName.length > 0 &&
            this.state.stateName.length > 0 &&
            this.state.countryName.length > 0 &&
            this.state.pinCode.length > 0 &&
            this.state.GSTno.length > 0 &&
            this.state.loginID.length > 0 &&
            this.state.password.length > 0 &&
            this.state.comPeried.length > 0 &&
            this.state.startDate >= null &&
            this.state.InsAmount.length > 0 &&
            this.state.noUsers.length > 0 &&
            this.state.subAmount.length > 0 &&
            this.state.emailID.length > 0) {
            this.setState({
                branchNameerror: false,
                branchNameHelperText: false,
                phoneNoerror: false,
                phoneNoHelperText: false,
                address1error: false,
                address1HelperText: false,
                cityNameerror: false,
                cityNameHelperText: false,
                stateNameerror: false,
                stateNameHelperText: false,
                countryNameerror: false,
                countryNameHelperText: false,
                pinCodeerror: false,
                pinCodeHelperText: false,
                GSTnoerror: false,
                GSTnoHelperText: false,
                loginIDerror: false,
                loginIDHelperText: false,
                passworderror: false,
                passwordHelperText: false,
                comPeriederror: false,
                comPeriedHelperText: false,
                startDateerror: false,
                startDateHelperText: false,
                InsAmounterror: false,
                InsAmountHelperText: false,
                noUserserror: false,
                noUsersHelperText: false,
                subAmounterror: false,
                subAmountHelperText: false,
                userTableTypeerror: false,
                userTableTypeHelperText: false,
                emailIDerror: false,
                emailIDHelperText: false

            }, () => { this.postBranchDetails() })


        } else {
            this.validateTextBox()
        }
    }

    validateTextBox = () => {
        if (this.state.branchName === "") {
            this.setState({
                branchNameerror: true,
                branchNameHelperText: 'Please Enter the Branch Name'
            })
        } else {
            this.setState({
                branchNameerror: false,
                branchNameHelperText: ''
            })
        } if (this.state.phoneNo === "") {
            this.setState({
                phoneNoerror: true,
                phoneNoHelperText: 'Please Enter the Phone No'
            })
        } else {
            this.setState({
                phoneNoerror: false,
                phoneNoHelperText: ''
            })
        } if (this.state.address1 === "") {
            this.setState({
                address1error: true,
                address1HelperText: 'Please Enter the Address'
            })
        } else {
            this.setState({
                address1error: false,
                address1HelperText: ''
            })
        } if (this.state.cityName === "") {
            this.setState({
                cityNameerror: true,
                cityNameHelperText: 'Please Enter the City'
            })
        } else {
            this.setState({
                cityNameerror: false,
                cityNameHelperText: ''
            })
        } if (this.state.stateName === "") {
            this.setState({
                stateNameerror: true,
                stateNameHelperText: 'Please Enter the State'
            })
        } else {
            this.setState({
                stateNameerror: false,
                stateNameHelperText: ''
            })
        } if (this.state.countryName === "") {
            this.setState({
                countryNameerror: true,
                countryNameHelperText: 'Please Enter the Country'
            })
        } else {
            this.setState({
                countryNameerror: false,
                countryNameHelperText: ''
            })
        } if (this.state.pinCode === "") {
            this.setState({
                pinCodeerror: true,
                pinCodeHelperText: 'Please Enter the PinCode'
            })
        } else {
            this.setState({
                pinCodeerror: false,
                pinCodeHelperText: ''
            })
        } if (this.state.GSTno === "") {
            this.setState({
                GSTnoerror: true,
                GSTnoHelperText: 'Please Enter the GST No'
            })
        } else {
            this.setState({
                GSTnoerror: false,
                GSTnoHelperText: ''
            })
        } if (this.state.loginID === "") {
            this.setState({
                loginIDerror: true,
                loginIDHelperText: 'Please Enter the Login ID'
            })
        } else {
            this.setState({
                loginIDerror: false,
                loginIDHelperText: ''
            })
        } if (this.state.password === "") {
            this.setState({
                passworderror: true,
                passwordHelperText: 'Please Enter the Password'
            })
        } else {
            this.setState({
                passworderror: false,
                passwordHelperText: ''
            })
        } if (this.state.comPeried === "") {
            this.setState({
                comPeriederror: true,
                comPeriedHelperText: 'Please Enter the Com.Peried'
            })
        } else {
            this.setState({
                comPeriederror: false,
                comPeriedHelperText: ''
            })
        } if (this.state.startDate == null) {
            this.setState({
                startDateerror: true,
                startDateHelperText: 'Please Select the Date'
            })
        } else {
            this.setState({
                startDateerror: false,
                startDateHelperText: ''
            })
        } if (this.state.InsAmount === "") {
            this.setState({
                InsAmounterror: true,
                InsAmountHelperText: 'Please Enter the Ins.Amount'
            })
        } else {
            this.setState({
                InsAmounterror: false,
                InsAmountHelperText: ''
            })
        } if (this.state.noUsers === "") {
            this.setState({
                noUserserror: true,
                noUsersHelperText: 'Please Enter the Users Count'
            })
        } else {
            this.setState({
                noUserserror: false,
                noUsersHelperText: ''
            })
        } if (this.state.subAmount === "") {
            this.setState({
                subAmounterror: true,
                subAmountHelperText: 'Please Enter the Sub.Amount'
            })
        } else {
            this.setState({
                subAmounterror: false,
                subAmountHelperText: ''
            })
        } if (this.state.userTableType === '') {
            this.setState({
                userTableTypeerror: true,
                userTableTypeHelperText: 'Please Select the specialization'
            })
        } else {
            this.setState({
                userTableTypeerror: false,
                userTableTypeHelperText: ''
            })
        } if (this.state.emailID === '') {
            this.setState({
                emailIDerror: true,
                emailIDHelperText: 'Please Select the Email ID'
            })
        } else {
            this.setState({
                emailIDerror: false,
                emailIDHelperText: ''
            })
        }
    }
    clear = () => {
        this.setState({
            branchName: '',
            phoneNo: '',
            address1: '',
            address2: '',
            cityName: '',
            stateName: 'Tamil Nadu',
            countryName: 'India',
            pinCode: '',
            GSTno: '',
            loginID: '',
            password: '',
            comPeried: '',
            startDate: null,
            InsAmount: '',
            noUsers: '',
            subAmount: '',
            renualDate: null,
            document1: '',
            department: '',
            uploadImage: '',
            error: false,
            helperText: '',
            userTableType: '',
            emailID: '',
            emailIDerror: false,
            emailIDHelperText: false,
            branchNameerror: false,
            branchNameHelperText: "",
            phoneNoerror: false,
            phoneNoHelperText: "",
            address1error: false,
            address1HelperText: "",
            cityNameerror: false,
            cityNameHelperText: "",
            stateNameerror: false,
            stateNameHelperText: "",
            countryNameerror: false,
            countryNameHelperText: "",
            pinCodeerror: false,
            pinCodeHelperText: "",
            GSTnoerror: false,
            GSTnoHelperText: "",
            loginIDerror: false,
            loginIDHelperText: "",
            passworderror: false,
            passwordHelperText: "",
            comPeriederror: false,
            comPeriedHelperText: "",
            startDateerror: false,
            startDateHelperText: "",
            InsAmounterror: false,
            InsAmountHelperText: "",
            noUserserror: false,
            noUsersHelperText: "",
            subAmounterror: false,
            subAmountHelperText: "",
            renualDateerror: false,
            renualDateHelperText: "",
            userTableTypeerror: false,
            userTableTypeHelperText: ""
        })
    }

    render() {
        return (
            <div>
                <div className='LogoutConHome'>
                    <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                        Logout
                    </Button>
                </div>
                <div className='FormContainer'>
                    <div className='emed_TopHeadNewClient'>
                        <div className='LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='Formlogo' />
                        </div>
                        <div className='emed_NewClientHeadTextCon'>
                            <p className='emed_NewClientHeadText'>New Branch Registration</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '1vw' }}>
                        {this.renderTextBoxCon()}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1vw' }}>
                        <Stack spacing={6} direction="row">
                            <Button variant="contained" onClick={this.Submit}>Next</Button>
                            {this.state.warMsg ?
                                <ToastMsg
                                    title={"Warning:"}
                                    msg={this.state.warnMsgText}
                                    severity={"warning"} />
                                : null
                            }
                            {this.state.errorMsg ?
                                <ToastMsg
                                    title={"Error:"}
                                    msg={this.state.errorMsgText}
                                    severity={"error"}
                                />
                                : null
                            }
                            <Button variant="outlined" onClick={this.clear}>Clear</Button>
                        </Stack>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddBranchDetails;