import React, { Component } from 'react';
import './SelectBranch.css';
import Imagepath from '../../../Utility/ImagePath';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ScrollArea from 'react-scrollbar';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import LogoutIcon from '@mui/icons-material/Logout';
import { localGetItem, localSetItem } from '../../../Utility/Serivce/CacheProviderService';

class SelectBranch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            branchDetails: [],
            searchText: '',
        }
    }
    componentDidMount() {
        const { location } = this.props
        if ((location.orgID && location.orgID) && (location.orgName && location.orgName)) {
            localSetItem("OrgID", location.orgID)
            localSetItem("OrgName", location.orgName)
            this.getBranchDetails()
        } else {
            this.getBranchDetails()
        }
    }
    getBranchDetails = () => {
        try {
            var OrgId = localGetItem("OrgID")
            ResetAPIService.getAll(Serviceurls.BRANCH_LIST_POST + "?organization_id=" + OrgId +
                "&branch_name=" + this.state.searchText)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            branchDetails: response.data.data
                        })
                    }
                }).catch(e => {
                    console.log(e.message)
                })
        } catch (e) {
            console.log(e.message, "error")
        }
    }
    renderCard = (orgName, branchName, id) => {
        const { history } = this.props
        return (
            <div className='eMed_Existing_OrgCrad' onClick={() => {
                this.props.history.push({ pathname: '/Home/Organization/Branch/AccessDetails' })
                localSetItem("BranchName", branchName)
                localSetItem("SelectedBranchID", id)
            }}>
                <div className='eMed_Exising_OrgName'>
                    <p className='eMed_Existing_OrgNameText' style={{ fontSize: orgName && orgName.length > 16 ? "1vw" : "1.3vw" }}>{orgName}</p>
                </div>
                <div className='eMed_Existing_OrgBranchName'>
                    <p style={{ fontSize: branchName && branchName.length > 18 ? "0.8vw" : "1vw" }}>{branchName}</p>
                </div>
            </div>
        )
    }
    searchTextBox = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => { this.getBranchDetails() });
    }

    render() {
        var states = this.state
        var OrgName = localGetItem("OrgName")
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='eMed_Select_Org_Con'>
                    <div className='emed_TopHeadExisting'>
                        <div className='eMed_Existing_LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='eMed_Existing_Formlogo' />
                        </div>
                        <div className='emed_Existing_HeadTextCon'>
                            <p className='emed_Existing_HeadText'>Select the Branch</p>
                        </div>
                    </div>
                    <div className='eMed_Existing_Org_SearchCon'>
                        <TextField id="outlined-basic3" placeholder='Search items'
                            value={this.state.searchText}
                            variant="outlined" size='small'
                            name={"searchText"}
                            onChange={this.searchTextBox}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'><SearchIcon style={{ color: '#888888', cursor: 'pointer' }} /></InputAdornment>
                                )
                            }} />
                    </div>
                    <ScrollArea className='eMed_Existing_Card_Scroll' horizontal={false}>
                        <div className='eMed_Existing_Org_CradCon'>
                            {states.branchDetails && states.branchDetails.length > 0 ? states.branchDetails.map((item) => {
                                return (
                                    this.renderCard(OrgName, item.branch_name, item.branch_id)
                                )
                            }) :
                                <div className='eMed_Existing_Org_NorecordCon'>
                                    <p className='eMed_Existing_Org_Norecord'>No Records To Show...</p>
                                </div>}

                        </div>
                    </ScrollArea>
                </div>

            </div>
        );
    }
}

export default SelectBranch;