export default class Imagepath {

    static Logo = require('../assets/Logo.png')
    static NewHospital = require('../assets/Newhospital.png')
    static ExistingHospital = require('../assets/Existinghospital.png')
    static Removal = require('../assets/Removehospital.png')
    static Back = require('../assets/Back.png')
    static AddBranch = require('../assets/Addbranch.png')
    static TrialVersion = require('../assets/TrailVersion.png')
    static User = require('../assets/User.png')
    static Config = require('../assets/Config.png')
    static Soon = require('../assets/Animation/Soon.gif')
}
