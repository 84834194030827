import React, { Component } from 'react';
import "./AccessDetails.css"
import Imagepath from '../../../Utility/ImagePath';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { localGetItem, localSetItem } from '../../../Utility/Serivce/CacheProviderService';

class AccessDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orgName: '',
            branchName: localGetItem("BranchName"),
            branch_ID: ''
        }
    }
    // componentDidMount(){
    //     const {location} = this.props
    //     if(location.branchID && location.branchID){
    //         var branchID = location.branchID
    //         this.setState({
    //             branch_ID: branchID
    //         })
    //     }
    // }

    renderCard = (image, name, label, path) => {
        const { history } = this.props
        return (
            <div className='eMed_Existing_OrgCrad' onClick={() => this.props.history.push({ pathname: path })}>
                <div className='eMed_Exising_OrgName'>
                    <img src={image} alt={name} className="eMed_AccessDetails_CardImg" />
                </div>
                <div className='eMed_Existing_OrgBranchName'>
                    <p style={{ fontSize: "1.1vw", fontWeight: 'bold' }}>{label}</p>
                </div>
            </div>
        )
    }

    render() {
        var states = this.state
        var OrgName = localGetItem("OrgName")
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='eMed_AccessDetails_Con'>
                    <div className='emed_TopHeadExisting'>
                        <div className='eMed_Existing_LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='eMed_Existing_Formlogo' />
                        </div>
                        <div className='emed_Existing_HeadTextCon'>
                            <p className='emed_Existing_HeadText'>{OrgName && OrgName ? OrgName : ""} - {states.branchName && states.branchName ? states.branchName : ''}</p>
                        </div>
                    </div>
                    <div className='eMed_AccessDetails_CardCon'>
                        {this.renderCard(Imagepath.User, "User", "Access User Details", "/ComingSoon")}
                        {this.renderCard(Imagepath.Config, "Config", "Configuration Details", '/Home/Organization/Branch/AccessDetails/Configuration')}
                    </div>
                </div>
            </div>
        );
    }
}

export default AccessDetails;