import React, { Component } from 'react';
import './AddOrganization.css'
import Imagepath from '../../../Utility/ImagePath';
import { TextField, Button, Stack } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import ToastMsg from '../../../CommonComponents/ToastMsg';
import { localSetItem } from '../../../Utility/Serivce/CacheProviderService';

class Oraganization extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orgName: '',
            phoneNo: '',
            address1: '',
            address2: '',
            cityName: '',
            stateName: 'Tamil Nadu',
            countryName: 'India',
            pinCode: '',
            GSTno: '',
            loginID: '',
            password: '',
            document1: '',
            document2: '',
            document3: '',
            uploadImage: '',
            selectCountry: '',
            selectState: '',
            emailID: '',
            isToastOpen: false,
            errorMsg: '',
            wrnMsg: false,
            warnMsgText: '',
            sendUploadImage: '',
            logoUrl: '',
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    postOrgDetails = () => {

        var logoURL = this.state.logoUrl ? this.dataURItoBlob(this.state.logoUrl) : ''
        try {
            const data = new FormData()
            data.append("login_id", this.state.loginID)
            data.append("password", this.state.password)
            data.append("organization_name", this.state.orgName)
            data.append("phone_no", this.state.phoneNo)
            data.append("address_1", this.state.address1)
            data.append("address_2", this.state.address2)
            data.append("city", this.state.cityName)
            data.append("state", this.state.stateName)
            data.append("country", this.state.countryName)
            data.append("pin_code", this.state.pinCode)
            data.append("gst_no", this.state.GSTno)
            data.append("document_1", this.state.document1)
            data.append("document_2", this.state.document2)
            data.append("document_3", this.state.document3)
            data.append("logo", logoURL)
            data.append("email", this.state.emailID)
            ResetAPIService.docCreate(data, Serviceurls.ORGANIZATION_POST)
                .then(response => {
                    if (response.data.status == "success") {
                        localSetItem("OrgID", response.data.organization_id)
                        this.props.history.push('/Home/NewHospital/AddBranch')
                    } else {
                        this.setState({
                            wrnMsg: true,
                            warnMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ wrnMsg: false })
                            }, 2000);
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        isToastOpen: true,
                        errorMsg: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ isToastOpen: false })
                        }, 2000);
                    })
                })

        }
        catch (e) {
            this.setState({
                isToastOpen: true,
                // errorMs  g: e.message
            }, () => {
                setTimeout(() => {
                    this.setState({ isToastOpen: false })
                }, 2000);
            })
        }
    }

    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    numInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            });
        }
    };
    selectCountry(val) {
        let states = this.state
        states["countryName"] = val
        this.setState({ states });
    }
    selectRegion(val) {
        let states = this.state
        states["stateName"] = val
        this.setState({ states });
    }
    renderTextBox = (label, placeholder, value, name, error, HelperText) => {
        var { countryName, stateName } = this.state
        return (
            <div>
                <div className='TextBoxContainer'>
                    <div className='TextLabel'>
                        <label>{label}:</label>
                    </div>
                    {label === "Document 01" || label === "Document 02" || label === "Document 03" || label === "Add Logo" ?
                        <div className='TextInputbox'>
                            {/* <TextField
                                id="outlined-basic"
                                style={{ width: '18vw' }}
                                placeholder={placeholder}
                                variant="outlined"
                                size='small'
                                type="file"
                                value={value}
                                accept='image/*'
                                name={name}
                                onClick={(event) => {
                                    this.setState({ uploadImage: event.target.value })
                                }} /> */}
                            <input className='emed_AddLogo_Org' type="file"
                                onChange={this.UploadImage} />
                            {label === "Add Logo" ?
                                <p className='HelperText'>Supported Format PNG,JPG &amp; JPEG</p> : null}
                            <img src={this.state.uploadImage} className='emed_Org_LogoView' />
                        </div> :
                        label === "Country" ?
                            <CountryDropdown className='emed_Org_Country'
                                style={{ borderColor: this.state.countryName === "" ? "red" : '#bbb9b9' }}
                                error={error}
                                helperText={HelperText}
                                value={countryName}
                                onChange={(val) => this.selectCountry(val)}
                                priorityOptions={["IN"]}
                                whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                            /> :
                            label === "State" ?
                                <RegionDropdown
                                    error={error}
                                    helperText={HelperText}
                                    className='emed_Org_Country'
                                    country={countryName}
                                    value={stateName}
                                    onChange={(val) => this.selectRegion(val)} />
                                :
                                <div className='TextInputbox1'>
                                    <TextField
                                        id="outlined-basic"
                                        style={{ width: '14.5vw' }}
                                        placeholder={placeholder}
                                        variant="outlined"
                                        size='small'
                                        name={name}
                                        value={value}
                                        error={error}
                                        inputProps={label === "Phone No" ? { maxLength: 10 } : ''}
                                        helperText={HelperText}
                                        onChange={name === "phoneNo" ? this.numInput : this.textInput} />
                                </div>
                    }


                </div>
                {/* 
                      */}
            </div>
        )
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    UploadImage = (e) => {
        var files = e.target.files[0]
        var url = ""
        this.getBase64(files, (result) => {
            url = result;
            this.setState({
                logoUrl: url
            })
        });
        this.setState({
            uploadImage: URL.createObjectURL(files),
        })

    }
    renderTextBoxCon = () => {
        // const [file, setFile] = useState();
        // function handleChange(e) {
        //     console.log(e.target.files);
        //     setFile(URL.createObjectURL(e.target.files[0]));
        // }
        return (
            <div className='FormTextBoxCon'>
                <div className='emed_FormTextBox'>
                    <div className='FormTextrow1'>
                        {this.renderTextBox("Oraganization Name", "Add Name", this.state.orgName, "orgName",
                            this.state.orgNameerror, this.state.orgNameHelperText)}
                        {this.renderTextBox("Phone No", "Add Mobile No", this.state.phoneNo, "phoneNo",
                            this.state.phoneNoerror, this.state.phoneNoHelpertext)}
                        {this.renderTextBox("Address 01", "Add Address", this.state.address1, "address1",
                            this.state.address1error, this.state.address1HelperText)}
                        {this.renderTextBox("Address 02", "Add Address 02", this.state.address2, "address2")}
                        {this.renderTextBox("Country", "Add Country", this.state.countryName, "countryName",
                            this.state.countryNameerror, this.state.countryNameHelperText)}
                        {this.renderTextBox("State", "Add State", this.state.stateName, "stateName",
                            this.state.stateNameerror, this.state.stateNameHelperText)}
                        {this.renderTextBox("City", "Add City", this.state.cityName, "cityName",
                            this.state.cityNameerror, this.state.cityNameHelperText)}
                        {this.renderTextBox("Pin Code", "Add Pin Code", this.state.pinCode, "pinCode",
                            this.state.pinCodeerror, this.state.pinCodeHelperText)}

                    </div>
                    <div className='FormTextrow2'>
                        {this.renderTextBox("GST No", "Add GST No", this.state.GSTno, "GSTno",
                            this.state.GSTnoerror, this.state.GSTnoHelperText)}
                        {this.renderTextBox("Email ID", "Enter Email ID", this.state.emailID, "emailID",
                            this.state.emailIDerror, this.state.emailIDHelperText)}
                        {this.renderTextBox("Login ID", "Enter Login ID", this.state.loginID, "loginID",
                            this.state.loginIDerror, this.state.loginIDHelperText)}
                        {this.renderTextBox("Password", "Create Password", this.state.password, "password",
                            this.state.passworderror, this.state.passwordHelperText)}
                        {/* {this.renderTextBox("Document 01", "Upload Your File", this.state.document1, "document1",
                            this.state.document1error, this.state.document1HelperText)} */}
                        {/* {this.renderTextBox("Document 02", "Upload Your File", this.state.document2, "document2")} */}
                        {/* {this.renderTextBox("Document 03", "Upload Your File", this.state.document3, "document3")} */}
                        {this.renderTextBox("Add Logo", "Upload Document", this.state.uploadImage, "uploadImage")}

                    </div>
                </div>
            </div>
        )
    }

    submit = () => {
        if (this.state.orgName.length > 0 &&
            this.state.phoneNo.length > 0 &&
            this.state.address1.length > 0 &&
            this.state.cityName.length > 0 &&
            this.state.stateName.length >= 0 &&
            this.state.countryName.length >= 0 &&
            this.state.pinCode.length > 0 &&
            this.state.GSTno.length > 0 &&
            this.state.loginID.length > 0 &&
            this.state.password.length > 0 &&
            this.state.emailID.length > 0
        ) {
            this.setState({
                orgNameerror: false,
                orgNameHelperText: false,
                phoneNoerror: false,
                phoneNoHelpertext: false,
                address1error: false,
                address1HelperText: false,
                cityNameerror: false,
                cityNameHelperText: false,
                stateNameerror: false,
                stateNameHelperText: false,
                countryNameerror: false,
                countryNameHelperText: false,
                pinCodeerror: false,
                pinCodeHelperText: false,
                GSTnoerror: false,
                GSTnoHelperText: false,
                loginIDerror: false,
                loginIDHelperText: false,
                passworderror: false,
                passwordHelperText: false,
                emailIDerror: false,
                emailIDHelperText: false
            }, () => { this.postOrgDetails() })
        } else {
            this.validateTextBox()
        }
    }
    validateTextBox = () => {

        if (this.state.orgName === "") {
            this.setState({
                orgNameerror: true,
                orgNameHelperText: "Please Enter the Org.Name"
            })
        } else {
            this.setState({
                orgNameerror: false,
                orgNameHelperText: ""
            })
        }
        if (this.state.phoneNo === "") {
            this.setState({
                phoneNoerror: true,
                phoneNoHelpertext: "Please Enter the Phone No"
            })
        } else {
            this.setState({
                phoneNoerror: false,
                phoneNoHelpertext: ""
            })
        } if (this.state.address1 === "") {
            this.setState({
                address1error: true,
                address1HelperText: "Please Enter the Address"
            })
        } else {
            this.setState({
                address1error: false,
                address1HelperText: ""
            })
        } if (this.state.cityName === "") {
            this.setState({
                cityNameerror: true,
                cityNameHelperText: "Please Enter the City"
            })
        } else {
            this.setState({
                cityNameerror: false,
                cityNameHelperText: ""
            })
        }
        if (this.state.stateName === "") {
            this.setState({
                stateNameerror: true,
                stateNameHelperText: "Please Enter the State"
            })
        } else {
            this.setState({
                stateNameerror: false,
                stateNameHelperText: ""
            })
        } if (this.state.countryName === "") {
            this.setState({
                countryNameerror: true,
                countryNameHelperText: "Please Enter the Country"
            })
        } else {
            this.setState({
                countryNameerror: false,
                countryNameHelperText: ""
            })
        } if (this.state.pinCode === "") {
            this.setState({
                pinCodeerror: true,
                pinCodeHelperText: "Please Enter the Pincode"
            })
        } else {
            this.setState({
                pinCodeerror: false,
                pinCodeHelperText: ""
            })
        } if (this.state.GSTno === "") {
            this.setState({
                GSTnoerror: true,
                GSTnoHelperText: "Please Enter the GST.No"
            })
        } else {
            this.setState({
                GSTnoerror: false,
                GSTnoHelperText: ""
            })
        } if (this.state.loginID === "") {
            this.setState({
                loginIDerror: true,
                loginIDHelperText: "Please Enter the LoginID"
            })
        } else {
            this.setState({
                loginIDerror: false,
                loginIDHelperText: ""
            })
        } if (this.state.password === "") {
            this.setState({
                passworderror: true,
                passwordHelperText: "Please Enter the Password"
            })
        } else {
            this.setState({
                passworderror: false,
                passwordHelperText: ""
            })
        }
        if (this.state.emailID === "") {
            this.setState({
                emailIDerror: true,
                emailIDHelperText: "Please Enter the Email"
            })
        } else {
            this.setState({
                emailIDerror: false,
                emailIDHelperText: ""
            })
        }
    }
    ClearValues = () => {
        this.setState({
            orgName: '',
            phoneNo: '',
            address1: '',
            address2: '',
            cityName: '',
            stateName: 'Tamil Nadu',
            countryName: 'India',
            pinCode: '',
            GSTno: '',
            loginID: '',
            password: '',
            document1: '',
            document2: '',
            document3: '',
            uploadImage: '',
            emailID: '',
            orgNameerror: false,
            orgNameHelperText: false,
            phoneNoerror: false,
            phoneNoHelpertext: false,
            address1error: false,
            address1HelperText: false,
            cityNameerror: false,
            cityNameHelperText: false,
            stateNameerror: false,
            stateNameHelperText: false,
            countryNameerror: false,
            countryNameHelperText: false,
            pinCodeerror: false,
            pinCodeHelperText: false,
            GSTnoerror: false,
            GSTnoHelperText: false,
            loginIDerror: false,
            loginIDHelperText: false,
            passworderror: false,
            passwordHelperText: false,
            emailIDerror: false,
            emailIDHelperText: false
        })
    }
    render() {
        const { history } = this.props
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='FormContainer'>
                    <div className='emed_TopHeadNewClient'>
                        <div className='LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='Formlogo' />
                        </div>
                        <div className='emed_NewClientHeadTextCon'>
                            <p className='emed_NewClientHeadText'>New Client Registration</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '1vw' }}>
                        {this.renderTextBoxCon()}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1vw' }}>
                        <Stack spacing={6} direction="row">
                            <Button variant="contained" onClick={this.submit}>Next</Button>
                            {this.state.isToastOpen ?
                                <ToastMsg
                                    title={"Error:"}
                                    msg={this.state.errorMsg}
                                    severity={"error"}
                                />
                                : null}
                            {this.state.wrnMsg ?
                                <ToastMsg
                                    title={"Warning:"}
                                    msg={this.state.warnMsgText}
                                    severity={"warning"}
                                />
                                : null}
                            <Button variant="outlined" onClick={this.ClearValues}>Clear</Button>
                        </Stack>
                    </div>
                </div>
            </div>
        );
    }
}

export default Oraganization;