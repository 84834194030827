import React, { Component } from 'react';
import './AccessDetails.css';
import Imagepath from '../../../Utility/ImagePath';
import {
    Button, Tab, Box, MenuItem, Select,
    FormControl, InputLabel, Checkbox, FormControlLabel, TextField
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { TabContext, TabList, TabPanel, timelineSeparatorClasses } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Stack } from '@mui/system';
import moment from 'moment';
import ToastMsg from '../../../CommonComponents/ToastMsg';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import { localGetItem } from '../../../Utility/Serivce/CacheProviderService';
import { DateRange } from 'react-date-range';


const Tabs = [
    { id: "0", TabName: 'Front Office', component: "" },
    { id: "1", TabName: 'Doctor', component: "" },
    { id: "2", TabName: 'Pharmacy', component: "" },

];
class Configuration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabID: '0',
            selectConfig: '',
            selectUHID: '',
            selectInvoice: '',
            selectToken: '',
            uhidPrefix: '',
            invoicePrefix: '',
            selectWaitTime: "2022-12-27T18:45:00.106Z",
            selectConsultTime: "2022-12-27T18:45:00.106Z",
            isQMS: false,
            isActive: false,
            errorMsg: false,
            branch_ID: '',
            configDetails: '',
            errorMsgget: false,
            errorMsggetText: '',
            successMsg: false,
            successMsgText: '',
        }
    }
    componentDidMount() {
        var branchID = localGetItem("SelectedBranchID")
        if (branchID) {
            this.setState({
                branch_ID: branchID
            }, () => {
                this.getConfigDetails()
            })
        }
        this.getConfigList()
    }
    getConfigDetails = () => {
        try {
            ResetAPIService.getAll(Serviceurls.GET_CONFIG_DETAILS + "?branch_id=" + this.state.branch_ID)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        this.setState({
                            configDetails: response.data.data,
                            invoicePrefix: data.invoice_prefix,
                            selectUHID: data.uhid_gen_type,
                            selectInvoice: data.invoice_gen_type,
                            selectToken: data.token_gen_type,
                            uhidPrefix: data.uhid_prefix,
                            isQMS: data.is_qms_enabled,
                            selectConfig: data.config_type_id,
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsgget: true,
                        errorMsggetText: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsgget: false })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsgget: true,
                errorMsggetText: e.response.data.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsgget: false })
                }, 2000);
            })
        }
    }

    getConfigList = () => {
        try {
            ResetAPIService.getAll(Serviceurls.GET_CONFIG_LIST)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            configList: response.data.data
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsgget: true,
                        errorMsggetText: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsgget: false })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsgget: true,
                errorMsggetText: e.response.data.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsgget: false })
                }, 2000);
            })
        }
    }
    handleChange = (event, newValue) => {
        this.setState({
            tabID: newValue
        })
    }
    selectConfig = (event) => {
        this.setState({ selectConfig: event.target.value })
    }
    selectUHID = (event) => {
        this.setState({ selectUHID: event.target.value })
    }
    selectInvoice = (event) => {
        this.setState({ selectInvoice: event.target.value })
    }
    selectToken = (event) => {
        this.setState({ selectToken: event.target.value })
    }
    renderCheckbox = (label, value, name) => {
        return (
            <div>
                <FormControlLabel control={<Checkbox checked={value} />} name={name} label={label} value={value}
                    onClick={(event) => {
                        var states = this.state
                        states[name] = event.target.checked
                        this.setState({ states })
                    }} />
            </div>
        )
    }
    renderTextBox = (label, value, name, width) => {
        return (
            <div>
                <TextField
                    id="outlined-basicconfig"
                    sx={{ width: width }}
                    label={label}
                    value={value}
                    name={name}
                    inputProps={{ maxLength: 4 }}
                    variant="outlined"
                    size='small'
                    onChange={this.textInput} />
            </div>
        )
    }
    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var isValid = this.textValidation(value)
        if (isValid || value === "") {
            this.setState({
                [name]: value
            });
        }
    }
    textValidation(text) {
        return /^[A-Za-z]+$/.test(text);
    }
    postConfigDetails = () => {
        var data = {
            "branch_id": this.state.branch_ID,
            "uhid_prefix": this.state.uhidPrefix,
            "invoice_prefix": this.state.invoicePrefix,
            "is_qms_enabled": this.state.isQMS,
            "token_gen_type": this.state.selectToken,
            "invoice_gen_type": this.state.selectInvoice,
            "uhid_gen_type": this.state.selectUHID,
            "config_type_id": this.state.selectConfig,
            "is_active": this.state.isActive,
        }
        try {
            ResetAPIService.create(data, Serviceurls.GET_CONFIG_DETAILS)
                .then(responce => {
                    if (responce.data.status == "success") {
                        this.setState({
                            successMsg: true,
                            successMsgText: responce.data.message,
                        }, () => {
                            setTimeout(() => {
                                this.setState({ successMsg: false })
                            }, 2000);
                        })
                    }
                }).catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsggetText: e.responce.data.message,
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsg: false })
                        }, 2000);
                    })
                })
        } catch (e) {
            this.setState({
                errorMsg: true,
                errorMsggetText: e.responce.data.message,
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 2000);
            })
        }
    }
    saveBtn = () => {
        if (this.state.selectConfig > 0 &&
            this.state.selectUHID.length > 0 &&
            this.state.selectInvoice.length > 0 &&
            this.state.selectToken.length > 0 &&
            this.state.uhidPrefix.length > 0 &&
            this.state.invoicePrefix.length > 0) {
            this.postConfigDetails()
        }
        else {
            this.setState({
                errorMsg: true
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 1000);
            })
        }
    }
    clearBtn = () => {
        this.setState({
            selectConfig: '',
            selectUHID: '',
            selectInvoice: '',
            selectToken: '',
            uhidPrefix: '',
            invoicePrefix: '',
            isQMS: false,
            isActive: false
        })
    }
    render() {
        var states = this.state
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='eMed_AccessDetails_Con'>
                    <div className='emed_TopHeadExisting'>
                        <div className='eMed_Existing_LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='eMed_Existing_Formlogo' />
                        </div>
                        <div className='emed_Existing_HeadTextCon'>
                            <p className='emed_Existing_HeadText'>Configuration</p>
                        </div>
                    </div>
                    <div>
                        <TabContext value={this.state.tabID}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                                    {Tabs.map((data) => (
                                        <Tab value={data.id} label={data.TabName} />
                                    )
                                    )}
                                </TabList>

                            </Box>
                            <TabPanel value={"0"}>
                                <div className='eMed_Configure_FO_Time'>
                                    <div>
                                        <div>
                                            <FormControl sx={{ width: '20vw' }}
                                                size='small'>
                                                <InputLabel id="demo-simple-select-label">Config</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectConfig}
                                                    label="Config"
                                                    onChange={this.selectConfig}
                                                >
                                                    {states.configList && states.configList.length > 0 ? states.configList.map((list) => {
                                                        return (
                                                            <MenuItem value={list.id}>{list.config_type}</MenuItem>
                                                        )
                                                    }) : null}

                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            {this.renderTextBox("UHID Prefix", this.state.uhidPrefix, "uhidPrefix", '20vw')}
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            <FormControl sx={{ width: '20vw' }}
                                                size='small'>
                                                <InputLabel id="demo-simple-select-label">UHID Format</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectUHID}
                                                    label="UHID Prefix"
                                                    onChange={this.selectUHID}
                                                >
                                                    <MenuItem value={"1"}>RA0000001</MenuItem>
                                                    <MenuItem value={"2"}>RA20220001</MenuItem>
                                                    <MenuItem value={"3"}>30032022001</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            {this.renderCheckbox("Is QMS Enabled", this.state.isQMS, "isQMS")}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '2vw' }}>
                                        <div>
                                            {this.renderTextBox("Invoice Prefix", this.state.invoicePrefix, "invoicePrefix", '20vw')}
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            <FormControl sx={{ width: '20vw' }}
                                                size='small'>
                                                <InputLabel id="demo-simple-select-label">Invoice Format</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectInvoice}
                                                    label="Invoice Prefix"
                                                    onChange={this.selectInvoice}
                                                >
                                                    <MenuItem value={"1"}>2022-2023/Dept/Bill-Number</MenuItem>
                                                    <MenuItem value={"2"}>Hospital-Name/2022-2023/Dept/Bill-Number</MenuItem>
                                                    <MenuItem value={"3"}>2022/Dept/Bill-Number</MenuItem>
                                                    <MenuItem value={"4"}>Hospital-Name/2022/Dept/Bill-Number</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    ampm={false}
                                                    openTo="hours"
                                                    views={['hours', 'minutes', 'seconds']}
                                                    inputFormat="HH:mm:ss"
                                                    mask="__:__:__"
                                                    label="Waiting Time"
                                                    value={this.state.selectWaitTime}
                                                    defaultValue={moment().format('LTS')}
                                                    onChange={(newValue) => {
                                                        this.setState({ selectWaitTime: newValue });
                                                    }}
                                                    renderInput={(params) => <TextField size='small' sx={{ width: '20vw' }} {...params} />}
                                                />
                                            </LocalizationProvider> */}
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            <FormControl sx={{ width: '20vw' }}
                                                size='small'>
                                                <InputLabel id="demo-simple-select-label">Token Gen Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.selectToken}
                                                    label="Token Gen Type"
                                                    onChange={this.selectToken}
                                                >
                                                    <MenuItem value={"1"}>Clinic</MenuItem>
                                                    <MenuItem value={"2"}>Specialization</MenuItem>
                                                    <MenuItem value={"3"}>Doctor</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    ampm={false}
                                                    openTo="hours"
                                                    views={['hours', 'minutes', 'seconds']}
                                                    inputFormat="HH:mm:ss"
                                                    mask="__:__:__"
                                                    label="Consulting Time"
                                                    value={this.state.selectConsultTime}
                                                    defaultValue={moment().format('LTS')}
                                                    onChange={(newValue) => {
                                                        this.setState({ selectConsultTime: newValue });
                                                    }}
                                                    renderInput={(params) => <TextField size='small' sx={{ width: '20vw' }} {...params} />}
                                                />
                                            </LocalizationProvider> */}
                                        </div>
                                        <div style={{ marginTop: '1vw' }}>
                                            {this.renderCheckbox("Is Active", this.state.isActive, "isActive")}
                                        </div>
                                    </div>
                                </div>
                                <div className='eMed_Cofig_Fo_Btn'>
                                    <Stack spacing={2} direction='row'>
                                        <Button variant='contained' onClick={() => { this.saveBtn() }}>Save</Button>
                                        <Button variant='outlined' onClick={this.clearBtn}>Clear</Button>
                                        <Button variant='contained' onClick={()=> {this.props.history.push("/Home")}}>Go Home</Button>
                                    </Stack>
                                </div>
                            </TabPanel>
                            <TabPanel value={"1"}>Doctor</TabPanel>
                            <TabPanel value={"2"}>Pharmacy</TabPanel>
                        </TabContext>
                    </div>
                </div>
                {this.state.errorMsg ?
                    <ToastMsg
                        title={"Error:"}
                        msg={"Please Enter All Details"}
                        severity={"error"}
                    /> : null}
                {this.state.errorMsgget ?
                    <ToastMsg
                        title={"Error:"}
                        msg={this.state.errorMsggetText}
                        severity={"error"}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        title={"Success:"}
                        msg={this.state.successMsgText}
                        severity={"success"}
                    />
                    : null}
            </div>
        );
    }
}

export default Configuration;