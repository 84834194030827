import './App.css';
import RouterComponent from './Utility/RouterComponent'

function App() {
  return (
      <div className="">
        <RouterComponent />
      </div>
  );
}

export default App;
