import React from "react";
import { Route, Switch } from "react-router-dom";
import SignIn from "../LoginPage/SignIn";
import Home from '../Common/Home';
import Oraganization from '../Common/NewHospital/Organization/AddOrganization';
import AddBranch from "../Common/NewHospital/Branch/AddBranch";
import AddBranchDetails from "../Common/NewHospital/Branch/AddBranchDetails";
import SuperAdminPanel from "../Common/NewHospital/AdminPanel/SuperAdminPanel";
import Deparment from "../Common/NewHospital/Department/Deparment";
import DoctorProfile from "../Common/NewHospital/DoctorProfile/DoctorProfile";
import SelectOrganization from "../Common/Existing Hospital/Organization/SelectOrganization";
import SelectBranch from "../Common/Existing Hospital/BranchDetails/SelectBranch";
import AccessDetails from "../Common/Existing Hospital/BranchAccessDetails/AccessDetails";
import Configuration from "../Common/Existing Hospital/BranchAccessDetails/Configuration";
import ComingSoon from "../UpComings/ComingSoon";

function RouterComponent() {
    return (
        <Switch>
            {/* New Hospital */}
            <Route exact path="/" render={props => <SignIn {...props} />} />
            <Route exact path="/Home" render={props => <Home {...props} />} />
            <Route exact path="/Home/NewHospital" render={props => <Oraganization {...props} />} />
            <Route exact path="/Home/NewHospital/AddBranch" render={props => <AddBranch {...props} />} />
            <Route exact path="/Home/NewHospital/AddBranchDetails" render={props => <AddBranchDetails {...props} />} />
            <Route exact path="/Home/NewHospital/AdminPanel" render={props => <SuperAdminPanel {...props} />} />
            <Route exact path="/Home/NewHospital/Deparment" render={props => <Deparment {...props} />} />
            <Route exact path="/Home/NewHospital/DoctorProfile" render={props => <DoctorProfile {...props} />} />
            {/* Existing Hospital */}
            <Route exact path="/Home/Organization" render={props => <SelectOrganization {...props} />} />
            <Route exact path="/Home/Organization/Branch" render={props => <SelectBranch {...props} />} />
            <Route exact path="/Home/Organization/Branch/AccessDetails" render={props => <AccessDetails {...props} />} />
            <Route exact path="/Home/Organization/Branch/AccessDetails/Adminpanel" render={props => <SuperAdminPanel {...props} />} />
            <Route exact path="/Home/Organization/Branch/AccessDetails/Configuration" render={props => <Configuration {...props} />} />

            <Route exact path="/ComingSoon" render={props => <ComingSoon {...props} />} />
        </Switch>
    )
}
export default RouterComponent;
