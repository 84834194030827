import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';

// const Alert = React.forwardRef<HTMLDivElement> (function Alert(
//     props,
//     ref,
//   ) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
//   });

class ToastMsg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toastOpen: true
        }
    }

    handleClose = () => {
        this.setState({
            toastOpen: false
        })
    }
    render() {
        return (
            <div>
                <Snackbar open={this.state.toastOpen} autoHideDuration={2000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} variant="filled" severity={this.props.severity} sx={{ width: '100%' }}>
                        <AlertTitle>{this.props.title}</AlertTitle>
                        {this.props.msg}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default ToastMsg;