import React, { Component } from 'react';
import Imagepath from '../Utility/ImagePath';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { display } from '@mui/system';

class ComingSoon extends Component {
    render() {
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div style={{
                    width: '98vw', height: '89vh', marginLeft: '1vw',
                    display: 'flex', justifyContent: 'center', alignContent: 'center'
                }}>
                    <img style={{
                        width: '40vw',
                        height: '60vh',
                    }} alt={"Coming Soon..."} src={Imagepath.Soon} />
                </div>
            </div>
        );
    }
}

export default ComingSoon;