//import { API_URL, API_V2_URL, API_QMS_URL, BUCKET_NAME, API_FR_URL } from "@env"

export class Serviceurls {

    // static DOMAIN = "https://dev.api.emedhub.in/v2/";
    // static BASE_DOMAIN = "https://dev.api.emedhub.in/";
    static QMS_DOMAIN = "https://dev.qms.emedhub.in/";


    static UAT_DOMAIN = "https://uat.v3.api.emedhub.in/v2/"
    static UAT_BASE_DOMAIN = "https://uat.v3.api.emedhub.in/"

    // beta or line url ----
    // api.emedhub.in
    // static UAT_DOMAIN = "https://api.emedhub.in/v2/"
    // static UAT_BASE_DOMAIN = "https://api.emedhub.in/"

    static DOMAIN = this.UAT_DOMAIN;
    static BASE_DOMAIN = this.UAT_BASE_DOMAIN;

    // static DOMAIN = API_V2_URL;
    // static BASE_DOMAIN = API_URL;
    // static QMA_DOMAIN = API_QMS_URL;
    // s3 Bucket name in Document Page
    //static s3BUCKET_NAME = BUCKET_NAME

    //Login
    static LOGIN_EMEDHUB_ADMIN = "auth/login/"

    static ORGANIZATION_POST = "administrator/organization/view/";
    static BRANCH_LIST_POST = "administrator/branch/view/";
    static DEPARTMENT_LIST_POST = "administrator/create/departments/";
    static USERS_GET_LIST = "administrator/login/users/view/";
    static CREATE_USER_LIST = "administrator/create/users/";
    static GET_USER_LIMIT = "administrator/users/limit/check";
    static USER_LIST_UPDATE = "administrator/update/users/";
    static GREATE_DOC_LIST = "administrator/create/doctor/";
    static GET_DOCTOR_LIST = "administrator/doctor/speciality";
    static GET_ORGANIZATION_DETAILS = "administrator/branch/adminpanel";
    static GET_CONFIG_DETAILS = "administrator/config/";
    static GET_CONFIG_LIST = "administrator/config-type/";

}
