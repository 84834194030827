import React, { Component } from 'react';
import './Department.css'
import Imagepath from '../../../Utility/ImagePath';
import { Button, FormControlLabel, Checkbox } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack } from '@mui/system';
import { localGetItem } from '../../../Utility/Serivce/CacheProviderService';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import ToastMsg from '../../../CommonComponents/ToastMsg';


class Deparment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fo: false,
            pharmacy: false,
            lab: false,
            ip: false,
            counsellor: false,
            img_lab: false,
            optomentry: false,
            ot: false,
            selectedCheckbox: '',
            helperText: '',
            btnArray: ["all", "doctor"],
            errorMsg: false,
            errorMsgText: '',
            warnMsg: false,
            warningText: ''
        }
    }

    postDepartment = () => {
        try {
            var BranchID = localGetItem("BranchID")
            var data = {
                "branch_id": BranchID,
                "department": this.state.btnArray
            }
            ResetAPIService.create(data, Serviceurls.DEPARTMENT_LIST_POST)
                .then(response => {
                    if (response.data.status == "success") {
                        var value = response.data.data
                        this.setState({
                            clinic_id: value.clinic_id,
                            counsellor_id: value.counsellor_id,
                            img_lab_id: value.img_lab_id,
                            lab_id: value.lab_id,
                            optomentry_id: value.optomentry_id,
                            ot_id: value.ot_id,
                            pharmacy_id: value.pharmacy_id
                        }, () => this.props.history.push({ pathname: '/Home/NewHospital/AdminPanel', state: this.state }))

                    }
                    else {
                        this.setState({
                            warnMsg: true,
                            warningText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ warnMsg: false })
                            }, 2000);
                        })
                    }
                })
                .catch(e => {
                    this.setState({
                        errorMsg: true,
                        errorMsgText: e.response.data.message
                    }, () => {
                        setTimeout(() => {
                            this.setState({ errorMsg: false })
                        }, 2000);
                    })
                })

        }
        catch (e) {
            this.setState({
                errorMsg: true,
                errorMsgText: e.response.data.message
            }, () => {
                setTimeout(() => {
                    this.setState({ errorMsg: false })
                }, 2000);
            })
        }
    }

    rendercheckBoxs = () => {
        return (
            <div className='emed_allCheckBoxes'>
                {this.checkBoxs("Front Office Admin", this.state.fo, 'fo')}
                {this.checkBoxs("Pharmacy", this.state.pharmacy, 'pharmacy')}
                {this.checkBoxs("Laboratory", this.state.lab, 'lab')}
                {this.checkBoxs("IP", this.state.ip, 'ip')}
                {this.checkBoxs("Counsellar", this.state.counsellor, 'counsellor')}
                {this.checkBoxs("Image Lab", this.state.img_lab, 'img_lab')}
                {/* {this.checkBoxs("Opthalmology", this.state.optomentry, 'optomentry')} */}
                {this.checkBoxs("OT", this.state.ot, 'ot')}
                <div className='emed_Department_helperText'><helperText>{this.state.helperText}</helperText></div>
            </div>
        )
    }
    checkBoxs = (label, value, name) => {
        return (
            <div className='emed_checkboxCon'>
                <FormControlLabel control={<Checkbox checked={value} />} name={name} onClick={(event) => {
                    var states = this.state
                    states[name] = event.target.checked
                    this.setState({
                        selectedCheckbox: name,
                        helperText: ''
                    }, () => {
                        var { btnArray } = this.state
                        var dep = this.state.selectedCheckbox
                        if (this.state.btnArray && this.state.btnArray.length > 0) {
                            var selectedIndex = this.state.btnArray.findIndex(item => item === this.state.selectedCheckbox)
                            if (selectedIndex != -1) {
                                btnArray.splice(selectedIndex, 1)
                            } else {
                                btnArray.push(dep)
                            }

                        } else {
                            btnArray.push(dep)
                            this.setState({
                                btnArray
                            })
                        }


                    })
                }} label={label} value={value} />

            </div>
        )
    }
    clear = () => {
        this.setState({
            fo: '',
            pharmacy: '',
            lab: '',
            ip: '',
            counsellor: '',
            img_lab: '',
            optomentry: '',
            ot: '',
            selectedCheckbox: '',
            helperText: '',
            btnArray: ["all", "doctor"]
        })
    }
    submit = () => {

        if (this.state.fo == true ||
            this.state.pharmacy == true ||
            this.state.lab == true ||
            this.state.ip == true ||
            this.state.counsellor == true ||
            this.state.img_lab == true ||
            this.state.optomentry == true ||
            this.state.ot == true) {
            this.setState({
                error: false,
                helperText: '',
            }, () => { this.postDepartment() })
        } else {
            this.validate()
        }
    }
    validate = () => {
        if (this.state.fo == false ||
            this.state.pharmacy == false ||
            this.state.lab == false ||
            this.state.ip == false ||
            this.state.counsellor == false ||
            this.state.img_lab == false ||
            this.state.optomentry == false ||
            this.state.ot == false) {
            this.setState({
                error: true,
                helperText: "Please select atleast one Department"
            })
        } else {
            this.setState({
                error: false,
                helperText: ""
            })
        }
    }
    render() {
        return (
            <div>
                <div className='LogoutConHome'>
                    <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                        Logout
                    </Button>
                </div>
                <div className='emed_DepartmentMainCon'>
                    <div className='emed_TopHeadNewClient'>
                        <div className='LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='Formlogo' />
                        </div>
                        <div className='emed_NewClientHeadTextCon'>
                            <p className='emed_NewClientHeadText'>Select The Departments</p>
                        </div>
                    </div>
                    <div className='emed_Checkbox1'>
                        {this.rendercheckBoxs()}
                    </div>
                    <div className='emed_Department_BtnGroup'>
                        <Stack spacing={2} direction='row'>
                            <Button variant='contained' onClick={this.submit}>Next</Button>
                            {this.state.errorMsg ?
                                <ToastMsg
                                    title={"Error:"}
                                    msg={this.state.errorMsgText}
                                    severity={"error"} />
                                : null}
                            {this.state.warnMsg ?
                                <ToastMsg
                                    title={"Warning:"}
                                    msg={this.state.warningText}
                                    severity={"warning"} />
                                : null}

                            <Button variant='outlined' onClick={this.clear}>Clear</Button>
                        </Stack>
                    </div>
                </div>
            </div>
        );
    }
}

export default Deparment;