import React, { Component } from 'react';
import Imagepath from '../Utility/ImagePath';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';

class Header extends Component {
    render() {
        return (
            <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
        );
    }
}

export default Header;