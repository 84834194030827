import React, { Component } from 'react';
import './SelectOrganization.css';
import Header from '../../../CommonComponents/Header';
import Imagepath from '../../../Utility/ImagePath';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ScrollArea from 'react-scrollbar';
import ResetAPIService from '../../../Utility/Serivce/ResetAPIService';
import { Serviceurls } from '../../../Utility/API/ServiceUrls';
import LogoutIcon from '@mui/icons-material/Logout';

class SelectOrganization extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orgDetails: [],
            searchText: ''
        }
    }
    componentDidMount() {
        this.getOrganizationDetails()
    }
    getOrganizationDetails = () => {
        try {
            ResetAPIService.getAll(Serviceurls.ORGANIZATION_POST + "?organization_name=" + this.state.searchText)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            orgDetails: response.data.data
                        })
                    }
                }).catch(e => {
                    console.log(e.message)
                })
        } catch (e) {
            console.log(e.message, "error")
        }
    }
    renderCard = (orgName, label, id) => {
        const { history } = this.props
        return (
            <div className='eMed_Existing_OrgCrad' onClick={() => this.props.history.push({ pathname: '/Home/Organization/Branch', orgID: id, orgName: orgName })}>
                <div className='eMed_Exising_OrgName'>
                    <p className='eMed_Existing_OrgNameText' style={{ fontSize: orgName && orgName.length > 16 ? "1vw" : "1.3vw" }}>{orgName}</p>
                </div>
                <div className='eMed_Existing_OrgBranchName'>
                    <p style={{ fontSize: label && label.length > 18 ? "0.8vw" : "1vw" }}>{label}</p>
                </div>
            </div>
        )
    }

    searchTextBox = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => { this.getOrganizationDetails() });
    }

    render() {
        var states = this.state
        return (
            <div>
                <div className='HeadView'>
                    <div className='BackCon' onClick={() => this.props.history.goBack('/')}>
                        <img src={Imagepath.Back} alt='Back' />
                    </div>
                    <div className='LogoutCon'>
                        <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => this.props.history.push('/')}>
                            Logout
                        </Button>
                    </div>
                </div>
                <div className='eMed_Select_Org_Con'>
                    <div className='emed_TopHeadExisting'>
                        <div className='eMed_Existing_LogoContainer'>
                            <img src={Imagepath.Logo} alt="Logo" className='eMed_Existing_Formlogo' />
                        </div>
                        <div className='emed_Existing_HeadTextCon'>
                            <p className='emed_Existing_HeadText'>Select the Organization</p>
                        </div>
                    </div>
                    <div className='eMed_Existing_Org_SearchCon'>
                        <TextField id="outlined-basic3" placeholder='Search Organization'
                            value={this.state.searchText}
                            variant="outlined" size='small'
                            name={"searchText"}
                            onChange={this.searchTextBox}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'><SearchIcon style={{ color: '#888888', cursor: 'pointer' }} /></InputAdornment>
                                )
                            }} />
                    </div>
                    <ScrollArea className='eMed_Existing_Card_Scroll' horizontal={false}>
                        <div className='eMed_Existing_Org_CradCon'>
                            {states.orgDetails && states.orgDetails.length > 0 ? states.orgDetails.map((item) => {
                                return (
                                    this.renderCard(item.organization_name, (item.org_branch_count ? item.org_branch_count : "-") + " Branch", item.id)
                                )
                            }) :
                                <div className='eMed_Existing_Org_NorecordCon'>
                                    <p className='eMed_Existing_Org_Norecord'>No Records To Show...</p>
                                </div>}

                        </div>
                    </ScrollArea>
                </div>

            </div>
        );
    }
}

export default SelectOrganization;